import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICollectionLocation, OperatingHours } from 'core';
import { LocationService } from '../location.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDialogComponent } from '../location-dialog/location-dialog.component';
import { HTMLInputEvent } from '../../model/util';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit {
  selectedLocation: ICollectionLocation;
  locations: ICollectionLocation[];

  constructor(private locationService: LocationService, private router: Router, private modal: NgbModal) {}

  ngOnInit() {
    this.locationService.locations.subscribe(loc => (this.locations = loc));
  }

  onSelect(location: ICollectionLocation) {
    this.selectedLocation = location;
    this.router.navigate(['/locations', location.id]);
  }

  delete(location: ICollectionLocation, event: HTMLInputEvent) {
    event.stopPropagation();
    this.modal.open(LocationDialogComponent).result.then(result => {
      if (!result) return;
      this.selectedLocation = null;
      this.locationService.delete(location.id).subscribe(res => this.router.navigate(['/locations']));
    });
  }

  hoursToString(hours) {
    return OperatingHours.hoursToString(hours);
  }
}
