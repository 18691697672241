<app-nav></app-nav>
<div>
  <p>
    <ngb-alert type="info" *ngIf="!staticAlertClosed" (close)="staticAlertClosed = true" [dismissible]="true"
      >All data are for the last 3 months</ngb-alert
    >
  </p>
  <app-commissions class="my-2"> </app-commissions>

  <div class="row">
    <div class="col-md-6">
      <app-order-value></app-order-value>
    </div>
    <div class="col-md-6">
      <app-product-revenue></app-product-revenue>
    </div>
  </div>

  <div class="row my-5">
    <div class="col-md-6">
      <app-recentorders></app-recentorders>
    </div>
    <div class="col-md-6">
      <app-top-products></app-top-products>
    </div>
  </div>
</div>
