import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  @Input()
  entity: string;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  delete() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
