import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report/report.component';
import { ChartsModule } from 'ng2-charts';
import { CommissionsComponent } from './commissions/commissions.component';
import { RecentordersComponent } from './recentorders/recentorders.component';
import { TopProductsComponent } from './top-products/top-products.component';
import { ProductRevenueComponent } from './product-revenue/product-revenue.component';
import { NgbCollapseModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationModule } from '../navigation/navigation.module';
import { OrderValueComponent } from './order-value/order-value.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ReportComponent,
    CommissionsComponent,
    RecentordersComponent,
    TopProductsComponent,
    ProductRevenueComponent,
    OrderValueComponent
  ],
  imports: [CommonModule, ChartsModule, NgbCollapseModule, NgbAlertModule, NavigationModule, RouterModule],
  exports: [ReportComponent]
})
export class DashboardModule {}
