import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingComponent } from './billing/billing.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AccountComponent } from './account/account.component';
import { NgbTooltipModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationModule } from '../navigation/navigation.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [ProfileComponent, BillingComponent, ContactsComponent, AccountComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTooltipModule,
    NgbAlertModule,
    NavigationModule,
    FileUploadModule,
    MatFormFieldModule
  ]
})
export class AccountModule {}
