<nav class="navbar navbar-expand-lg navbar-light pl-0">
  <button
    class="navbar-toggler"
    type="button"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <i class="fas fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a routerLink="/overview" class="nav-link" routerLinkActive="selected">Overview</a>
      </li>
      <li class="nav-item">
        <a routerLink="/account" class="nav-link" routerLinkActive="selected">Account</a>
      </li>
      <li class="nav-item">
        <a routerLink="/locations" class="nav-link" routerLinkActive="selected">Locations</a>
      </li>
      <li class="nav-item">
        <a routerLink="/products" class="nav-link" routerLinkActive="selected">Products</a>
      </li>
      <li class="nav-item">
        <a routerLink="/orders" class="nav-link" routerLinkActive="selected">Orders</a>
      </li>
    </ul>
  </div>
</nav>
<a href="#main-menu-toggle" class="backdrop" hidden></a>
