import { Component, OnInit } from '@angular/core';
import { NOTIFICATION } from 'core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {
  notifications = NOTIFICATION;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  delete() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
