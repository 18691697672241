import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  add() {
    this.router.navigate(['/locations/new']);
  }
}
