<h4>Revenue per Product</h4>
<div class="d-flex align-items-center flex-column">
  <canvas
    *ngIf="ready"
    baseChart
    [data]="chartData"
    [labels]="chartLabels"
    chartType="pie"
    [colors]="chartColors"
  ></canvas>
</div>
