import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CanComponentDeactivate } from '../can-deactivate.guard';
import { ProductsService } from '../products.service';
import { DialogComponent } from './dialog/dialog.component';
import { WizardService } from './wizard.service';

@Component({
  selector: 'app-product-wizard',
  templateUrl: './product-wizard.component.html',
  styleUrls: ['./product-wizard.component.scss']
})
export class ProductWizardComponent implements OnInit, CanComponentDeactivate {
  private _name: string;
  private _dirty = false;

  constructor(private productService: ProductsService, private modal: NgbModal, private wizard: WizardService) {}

  ngOnInit() {
    this.productService.currentProduct
      .pipe(
        filter(prod => prod.isPresent),
        map(prod => prod.value.name)
      )
      .subscribe(name => (this._name = name));

    this.wizard.dirty.subscribe(val => (this._dirty = val));
  }

  get name() {
    return this._name ? ' - ' + this._name : '';
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this._dirty) {
      const modalRef = this.modal.open(DialogComponent);
      if (modalRef.result) {
        this.productService.reset();
      }
      return modalRef.result;
    }
    return true;
  }
}
