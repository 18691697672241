<div class="row">
  <div class="col">
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="shippingCheck" [formControl]="allowDelivery" />
        <label class="form-check-label" for="shippingCheck">Allow Delivery</label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" [formControl]="allowCollection" id="collectionCheck" />
        <label class="form-check-label" for="collectionCheck">Allow Collection</label>
      </div>
    </div>
    <div class="row">
      <div *ngIf="allowDelivery.value" class="col-12">
        <h5>Delivery</h5>
        <form [formGroup]="deliveryDetailsForm">
          <div *ngIf="batch" class="form-group">
            <ngb-alert type="info" [dismissible]="false"
              >Please note that the delivery fee should be specified per order or per batch.</ngb-alert
            >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="order"
                formControlName="order_or_batch"
                id="perOrderCheck"
              />
              <label class="form-check-label" for="perOrderCheck">Delivery fee calculated per order</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="batch"
                formControlName="order_or_batch"
                id="perBatchCheck"
              />
              <label class="form-check-label" for="perBatchCheck">Delivery fee calculated per batch</label>
            </div>
          </div>
          <div class="form-group">
            <label>Usual Delivery Time:</label>
            <div class="row">
              <div class="col-md-3">
                <input type="number" class="form-control" formControlName="delivery_time_days" />
              </div>
              <div class="col-md-3">days</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-delivery-rates [is_quote]="is_quote" [(rates)]="rates"></app-delivery-rates>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div *ngIf="allowCollection.value" class="col-12">
        <h5>Collection Locations</h5>
        <div class="form">
          <div class="form-group">
            <label for="collectionLocation">Location:</label>
            <div class="row">
              <div class="col-md-5 col-xs-10 col-10">
                <select class="form-control" [(ngModel)]="selectedLoc">
                  <option *ngFor="let loc of available_locations" [ngValue]="loc">{{ loc.name }}</option>
                </select>
              </div>
              <div class="col-auto pl-0">
                <button class="btn ac-btn-primary" (click)="addLocation()" [disabled]="!selectedLoc">
                  <i class="fas fa-plus-circle icon-btn"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-5 col-12 list-group">
            <div *ngFor="let location of collection_locations">
              <div class="list-group-item">
                <span>{{ location.name }}</span
                ><i class="fas fa-trash icon-btn float-right" (click)="deleteLocation(location)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col"></div>
      <div class="col-md-6 col-12 float-right">
        <div class="form-group row my-5">
          <div class="col-0 col-lg"></div>
          <div class="col-lg-3 col-4">
            <button class="btn btn-block ac-btn-secondary" type="button" (click)="back()">Back</button>
          </div>
          <div class="col-lg-3 col-4">
            <button class="btn btn-block ac-btn-secondary" type="button" (click)="cancel()">Cancel</button>
          </div>
          <div class="col-lg-3 col-4">
            <button class="btn btn-block ac-btn-primary" type="button" (click)="save()" [disabled]="invalid">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
