<header>
  <nav class="navbar navbar-dark bg-dark mb-3">
    <a class="navbar-brand" routerLink="/">
      <img [src]="logo" alt="{{ company }} logo" class="img-thumbnail merchant-logo mx-2" />
      {{ company }} Dashboard
    </a>

    <div class="navbar-expand ml-auto navbar-nav">
      <div class="navbar-nav mr-auto">
        <a *ngIf="authStatus" class="nav-link login" (click)="signout()">{{ authStatus }}</a>
      </div>
    </div>
  </nav>

  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <a [href]="SHOP_URL">
          <img src="assets/img/agrichecker.logo_.svg" style="max-height: 60px" />
        </a>
      </div>

      <div class="col-md-6">
        <div class="row align-items-center">
          <div class="col-md-3"></div>
          <div class="col-md-6"></div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="container">
    <div class="row ml-1">
      <a class="ac-breadcrumb-links px-1" [href]="SHOP_URL"><i class="fas fa-home"></i></a> &gt;
      <a class="ac-breadcrumb-links px-1" href="{{ breadcrumb?.url }}">{{ breadcrumb?.title }}</a>
    </div>
  </div>
  <hr />
</header>
