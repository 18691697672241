import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dictionary } from 'core';
import { HTMLInputEvent } from '../model/util';

class ComponentError {
  private errors = new Dictionary<string>();

  hasError(key: FileErrors): boolean {
    return this.errors.containsKey(key.toString());
  }

  setError(key: FileErrors, error: string) {
    this.errors.add(key.toString(), error);
  }

  errorMessage(key: FileErrors) {
    return this.errors.item(key.toString());
  }

  clear() {
    this.errors.clear();
  }

  get error_count() {
    return this.errors.count;
  }
}

enum FileErrors {
  size_limit_exceeded = 'size_limit_exceeded',
  multiple_files = 'multiple_files',
  read_error = 'read_error'
}

export interface FileContent {
  file: File;
  content: any;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() src = new EventEmitter<FileContent>();
  @Input() accept: string[];
  @Input() size_limit: string;
  @Input() type: string;

  errors: ComponentError;

  constructor() {
    this.errors = new ComponentError();
  }

  ngOnInit() {}

  private parseSize() {
    const s = this.size_limit.trim().toLowerCase();
    const unit = s[s.length - 1];
    const size = +s.slice(0, -1);

    if (unit === 'm') {
      return size * 1000;
    } else if (unit === 'k') {
      return size;
    } else {
      throw new Error("Invalid size unit specified.  Only 'm, M, k and K allowed'");
    }
  }

  readFile(event: HTMLInputEvent) {
    this.errors.clear();
    const files = event.target.files;
    if (files.length > 1) {
      this.errors.setError(FileErrors.multiple_files, 'Only a single file allowed');
      return;
    }
    const file = files[0];
    const max_size = this.parseSize();
    if (file && file.size / 1000 > max_size) {
      this.errors.setError(FileErrors.size_limit_exceeded, 'File size limit exceeded');
      return;
    }
    const mediaReader = new FileReader();
    mediaReader.onloadend = (e: any) => {
      this.src.emit({
        file: file,
        content: e.target.result
      });
    };
    if (file) {
      try {
        mediaReader.readAsArrayBuffer(file);
      } catch (ex) {
        this.errors.setError(FileErrors.read_error, 'There was an error reading the file');
      }
    } else {
      this.errors.setError(FileErrors.read_error, 'There was an error reading the file');
    }
  }

  get error() {
    return this.errors.error_count > 0;
  }
}
