import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbTypeaheadModule, NgbTooltipModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { DeliveryRatesComponent } from './delivery-rates/delivery-rates.component';
import { DialogComponent } from './dialog/dialog.component';
import { PriceStructureComponent } from './price-structure/price-structure.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
  declarations: [
    ProductDetailsComponent,
    PriceStructureComponent,
    DeliveryDetailsComponent,
    DeliveryRatesComponent,
    DialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbAlertModule,
    MatFormFieldModule
  ],
  entryComponents: [DialogComponent]
})
export class ProductWizardModule {}
