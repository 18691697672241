import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { S3Uploader } from 'core';
import { AuthService } from './auth/auth.service';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  constructor(private auth: AuthService) {}

  getS3Uploader(bucket: string, extension?: string) {
    return new S3Uploader(
      {
        region: environment.region,
        s3_bucket: bucket,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: environment.id_pool_id,
          IdentityId: this.auth.cognito_identity_id,
          Logins: {
            'cognito-identity.amazonaws.com': this.auth.cognito_token
          }
        })
      },
      extension
    );
  }
}
