import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  name: string;
  contactNumber: string;
  label: string;

  constructor(private userService: UserService) {}

  ngOnInit() {}

  addContact(name, tel, label) {
    const contact = {
      name: name,
      number: tel,
      label: label
    };
    this.userService.addContact(contact);
  }

  deleteContact(contact) {
    this.userService.deleteContact(contact.id);
  }

  get contacts() {
    return this.userService.contacts;
  }
}
