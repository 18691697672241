<div class="my-5">
  <ngb-alert [type]="alert?.type" *ngIf="alert?.showAlert" (close)="alert.showAlert = false" [dismissible]="true">{{
    alert?.message
  }}</ngb-alert>
  <div *ngIf="order">
    <app-order-status [order]="order" (orderUpdateRequest)="requestUpdate($event)"></app-order-status>
    <div class="mb-5">
      <acx-order-details [order]="order?.order" [deliveryMethod]="order?.delivery_method"></acx-order-details>
    </div>
    <app-order-address [order]="order"></app-order-address>
  </div>
</div>
