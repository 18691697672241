<form [formGroup]="productDetailsForm" (ngSubmit)="next()">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label for="selectCategory">Select Category:</label>
        <select class="form-control" id="selectCategory" formControlName="productCategory">
          <option *ngFor="let category of categories?.values()" [ngValue]="category.name">{{ category.name }} </option>
        </select>
      </div>
      <div class="form-group">
        <label>Name:</label>
        <input
          class="form-control"
          type="text"
          name="productName"
          formControlName="productName"
          required
          minlength="4"
        />
        <div *ngIf="productName.invalid && (productName.dirty || productName.touched)" class="mt-2">
          <mat-error *ngIf="productName.hasError('required')">
            {{ errors.REQUIRED_PRODUCT_NAME }}
          </mat-error>
          <mat-error *ngIf="productName.hasError('minlength')">
            {{ errors.SHORT_PRODUCT_NAME }}
          </mat-error>
        </div>
      </div>

      <div class="form-group">
        <label>Description:</label>
        <textarea class="form-control" name="description" rows="3" formControlName="description"></textarea>
        <div *ngIf="description.invalid && (description.dirty || description.touched)" class="mt-2">
          <mat-error *ngIf="description.hasError('required')">
            {{ errors.REQUIRED_PRODUCT_DESCRIPTION }}
          </mat-error>
        </div>
      </div>

      <div class="form-group">
        <label for="uploadFile">Upload Product Image:</label>
        <div class="row">
          <div class="col-md-4 col-6">
            <div *ngIf="product_image; else default" class="mb-3">
              <img id="prodImage" class="img-thumbnail rounded" [src]="product_image" alt="Product Image" />
            </div>
            <ng-template #default>
              <i class="fas fa-box-open fa-5x ac-product-logo mb-3"></i>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-5">
            <div *ngIf="loading" class="spinner-border my-1" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <app-file-upload
              (src)="handleImage($event)"
              [accept]="'image/png,image/jpg,image/jpeg'"
              [size_limit]="'1M'"
            ></app-file-upload>
          </div>
        </div>
      </div>

      <div *ngIf="!isGeneral">
        <div class="form-group">
          <label>Select the main product attribute field:</label>
          <select
            class="form-control"
            id="attribute1Select"
            formControlName="attribute1"
            [ngbTooltip]="attribute1Tooltip"
          >
            <option *ngFor="let attribute of attributes" [ngValue]="attribute.name">{{ attribute.name }}</option>
          </select>
          <ng-template #attribute1Tooltip>Primary attribute to display on product summary.</ng-template>
        </div>

        <div class="form-group">
          <label>Select the secondary product attribute field:</label>
          <select
            class="form-control"
            id="attribute2Select"
            formControlName="attribute2"
            [ngbTooltip]="attribute2Tooltip"
          >
            <option *ngFor="let attribute of attributes" [ngValue]="attribute.name">{{ attribute.name }}</option>
          </select>
          <ng-template #attribute2Tooltip>Secondary attribute to display on product summary.</ng-template>
        </div>
      </div>

      <div class="form-group" formArrayName="fields">
        <div *ngFor="let field of fields; let i = index">
          <!-- The repeated field template -->
          <label *ngIf="!isBooleanField(field.type)">{{ field.name }}: </label>
          <div [ngSwitch]="mapType(field.type)" class="form-group">
            <select *ngSwitchCase="'select'" class="form-control" id="selectFieldValue" [formControlName]="i" required>
              <option *ngFor="let val of field.type" [ngValue]="val">{{ val }}</option>
            </select>
            <label *ngSwitchCase="'checkbox'"
              >{{ field.name }}: <input class="ml-3" type="checkbox" [formControlName]="i"
            /></label>
            <input *ngSwitchCase="'text'" class="form-control" type="text" [formControlName]="i" required />
            <input *ngSwitchCase="'number'" class="form-control" type="number" [formControlName]="i" required />

            <div *ngSwitchCase="'url'" class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3">www</span>
              </div>
              <input
                type="URL"
                class="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
                [formControlName]="i"
                required
              />
            </div>
            <div *ngSwitchCase="'upload'">
              <div *ngIf="uploadReady(field)" class="mb-2">
                <i class="far fa-file-pdf fa-2x mr-3"></i
                ><a [href]="uploadSrc(field)" target="_blank">{{ uploadName(field) }}</a>
              </div>

              <div *ngIf="uploading" class="spinner-border my-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <app-file-upload
                (src)="handleUploadField(field, $event)"
                [accept]="'application/pdf'"
                [size_limit]="'2M'"
              ></app-file-upload>
            </div>
          </div>

          <div>
            <div *ngIf="!isBooleanField(field.type) && productDetailsForm.invalid && productDetailsForm.dirty">
              <div
                *ngIf="
                  productDetailsForm
                    .get('fields')
                    .get(i.toString())
                    .hasError('required')
                "
                class="mt-2"
              >
                <mat-error>{{ errors.REQUIRED }}</mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <h5>Product Type</h5>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="Shop"
            id="addToShopCheck"
            checked
            formControlName="shopOrClassifiedOrQuote"
          />
          <label class="form-check-label" for="addToShopCheck">Available for Checkout</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="Quote"
            id="quoteCheck"
            formControlName="shopOrClassifiedOrQuote"
          />
          <label class="form-check-label" for="quoteCheck">Request a Quote</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="Classified"
            id="classifiedCheck"
            formControlName="shopOrClassifiedOrQuote"
          />
          <label class="form-check-label" for="classifiedCheck">Classified</label>
        </div>
      </div>

      <div *ngIf="isShop" class="form-group">
        <div>
          <label>Available Stock</label>
          <input class="form-control" type="number" formControlName="stock" required min="0" />
          <div *ngIf="stock.invalid && stock.enabled" class="mt-2">
            <mat-error *ngIf="stock.hasError('required') || stock.hasError('min')">
              {{ errors.MINIMUM_STOCK }}
            </mat-error>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6"></div>
  </div>
  <div class="form-group row mb-5">
    <div class="col-md-6 col-12">
      <div class="row">
        <div class="col-md col-0"></div>
        <div class="col-md-4 col-6">
          <button class="btn btn-block ac-btn-secondary" type="button" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-md-4 col-6">
          <button class="btn btn-block ac-btn-primary" type="submit" [disabled]="invalid">{{ buttonText }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
