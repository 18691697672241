<div class="modal-header">
  <h4 class="modal-title">You have unsaved changes</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to continue without saving your changes</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-outline-dark" (click)="continue()">Continue</button>
</div>
