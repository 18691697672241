import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ApiService,
  CreateProductRequest,
  DEFAULT_PRODUCT,
  DeliveryDetails,
  Dictionary,
  Endpoints,
  IDictionary,
  ImageService,
  ImageSize,
  IProduct,
  IProductsResult,
  Namespace,
  Optional,
  ProductCategories,
  ProductCollectionLocation,
  ProductStatus,
  ProductType,
  PaginationParams,
  NotificationService,
  NOTIFICATION
} from 'core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

interface ProductRoute {
  id: string;
  wizard_page: String;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private _products: IDictionary<IProduct>;
  private _categories: ProductCategories;
  private _currentProduct$: Subject<Optional<IProduct>> = new BehaviorSubject(Optional.empty());
  private _tempProduct: IProduct;

  constructor(
    private api: ApiService,
    private router: Router,
    private imgService: ImageService,
    private notificationService: NotificationService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        filter(url => url.startsWith('/products/edit')),
        map(url => this.parseUrl(url))
      )
      .subscribe((res: ProductRoute) => {
        this.getProduct(res.id).ifPresent(prod => this._currentProduct$.next(Optional.of(prod)));
      });
  }

  private parseUrl(url: string): ProductRoute {
    const url_part = url.slice(14);
    if (url_part.startsWith('/')) {
      const url_segments = url_part.slice(1).split('/');
      return {
        id: url_segments[0],
        wizard_page: url_segments[1]
      };
    }
  }

  private add(product: IProduct): Observable<IProduct> {
    return this.api.post(Namespace.ac, Endpoints.products, this.mapFromProduct(product));
  }

  private update(product): Observable<IProduct> {
    return this.api.put(Namespace.ac, Endpoints.products + '/' + product.id, this.mapFromProduct(product));
  }

  private mapFromProduct(prod: IProduct): CreateProductRequest {
    let out = {} as CreateProductRequest;
    out = Object.assign(out, prod);
    out.price = prod.price.getOrElse(null);
    out.delivery = prod.delivery.isPresent ? prod.delivery.value.data : null;
    out.collection_locations = prod.collection_locations.isPresent ? prod.collection_locations.value : null;

    out.category_id = this._categories.getIdByName(prod.category.name);
    return out;
  }

  getProductImage(img_id: string, size: ImageSize) {
    return this.imgService.getImage(img_id, size);
  }

  commit(prod: IProduct) {
    this._currentProduct$.next(Optional.of(prod));
  }

  save(prod: IProduct): Observable<Optional<IProduct>> {
    let obs: Observable<IProduct>;

    if (prod.id) {
      obs = this.update(prod);
    } else {
      obs = this.add(prod);
    }
    return obs.pipe(
      tap(_ => this.reset()),
      map(p => Optional.of(p))
    );
  }

  reset() {
    this._tempProduct = null;
  }

  delete(id: string) {
    return this.api.delete(Namespace.ac, Endpoints.products + '/' + id).pipe(
      tap(_ => {
        this._currentProduct$.next(Optional.empty());
        this.reset();
      })
    );
  }

  addToClassifieds(id: string) {
    const prod = this.getProduct(id);
    return prod
      .ifPresent(p => {
        p.product_type = ProductType.classified;
      })
      .pipe(switchMap(p => this.save(p)));
  }

  addToShop(id: string) {
    const prod = this.getProduct(id);
    return prod
      .ifPresent(p => {
        p.product_type = ProductType.shop;
      })
      .pipe(switchMap(p => this.save(p)));
  }

  publish(id: string) {
    const prod = this.getProduct(id);
    return prod
      .ifPresent(p => {
        p.status = ProductStatus.pending;
      })
      .pipe(
        switchMap(p => this.save(p)),
        tap(_p => {
          this.notificationService.openSnackbar({ data: { message: NOTIFICATION.PRODUCT_PUBLISHED, type: 'info' } });
        })
      );
  }

  unPublish(id: string) {
    const prod = this.getProduct(id);
    return prod
      .ifPresent(p => {
        p.status = ProductStatus.draft;
      })
      .pipe(
        switchMap(p => this.save(p)),
        tap(_p => {
          this.notificationService.openSnackbar({
            data: { message: NOTIFICATION.PRODUCT_UNPUBLISHED, type: 'success' }
          });
        })
      );
  }

  private getProduct(id: string): Optional<IProduct> {
    if (id === 'new') {
      if (!this._tempProduct) {
        this._tempProduct = { ...DEFAULT_PRODUCT };
      }
      return Optional.of(this._tempProduct);
    }
    if (!this._products) {
      this.getProducts().subscribe(prods => {
        this._currentProduct$.next(Optional.of(prods.results.find(prod => prod.id === parseInt(id, 10))));
      });
      return Optional.empty();
    } else {
      return Optional.of(this._products.item(id));
    }
  }

  get currentProduct(): Observable<Optional<IProduct>> {
    return this._currentProduct$;
  }

  get productsData(): Array<IProduct> {
    return this._products ? this._products.values() : [];
  }

  getProducts(params?: PaginationParams) {
    return this.fetchCategories().pipe(
      switchMap(_ =>
        this.api.get(Namespace.ac, Endpoints.products, params).pipe(
          map(({ results, ...rest }: IProductsResult) => {
            return {
              results: this.mapToProducts(results),
              ...rest
            };
          }),
          tap(response => (this._products = Dictionary.from(response.results, 'id')))
        )
      )
    );
  }

  /**
   *
   * @param data The product data to transform @Product
   *
   */
  private mapToProducts(data): IProduct[] {
    return data.map(d => {
      const product = d as IProduct;
      product.price = Optional.of(d.price);
      product.category = this._categories.getById(d.category_id);
      product.attributes = product.attributes ? product.attributes : ['', ''];
      product.product_type = product.product_type ? product.product_type : ProductType.classified;
      product.order_size = product.order_size ? product.order_size : { min: 1, max: 10 };
      product.unit = product.unit ? product.unit : '';
      product.delivery = this.mapDeliveryDetails(d.delivery);
      product.status = product.status ? product.status : ProductStatus.draft;
      product.batch_size = product.batch_size ? product.batch_size : null;
      product.collection_locations = this.mapCollectionLocations(d.collection_locations);

      if (product.product_type === ProductType.quote) {
        product.price = Optional.empty();
      }
      return product;
    });
  }

  private mapDeliveryDetails(data: any): Optional<DeliveryDetails> {
    if (data.hasOwnProperty('delivery_time_days')) {
      const del = DeliveryDetails.from(data);
      return Optional.of(del);
    } else if (data.hasOwnProperty('deliveryTimeInDays')) {
      const del = new DeliveryDetails(data.deliveryTimeInDays);
      del.rates = data._rates;
      return Optional.of(del);
    }
    return Optional.empty();
  }

  private mapCollectionLocations(data: any): Optional<ProductCollectionLocation[]> {
    if (data.length > 0) {
      return Optional.of(data);
    }

    return Optional.empty();
  }

  private fetchCategories() {
    return this.api.get(Namespace.ac, Endpoints.categories).pipe(
      map(res =>
        res.map(r => {
          r.fields = r.fields === '' ? [] : r.fields;
          return r;
        })
      ),
      tap(res => (this._categories = new ProductCategories(res)))
    );
  }

  get categories(): ProductCategories {
    if (!this._categories) {
      this.fetchCategories().subscribe();
    }
    return this._categories;
  }
}
