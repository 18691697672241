import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  staticAlertClosed = false;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this.reportService.loadOrderAnalytics();
  }
}
