<div class="modal-header justify-content-center">
  <h4>{{ display_name }}</h4>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row justify-content-center">
      <label><i class="fas fa-phone"></i> {{ customer?.tel }}</label>
    </div>
    <div class="row justify-content-center">
      <label><i class="fas fa-envelope"></i> {{ customer?.email }}</label>
    </div>
    <div class="row justify-content-center mt-4">
      <button class="btn ac-btn-primary col-md-4" (click)="close()">OK</button>
    </div>
  </div>
</div>
