import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportService, OrderAnalytics, RevenueItem } from '../report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-revenue',
  templateUrl: './product-revenue.component.html',
  styleUrls: ['./product-revenue.component.scss']
})
export class ProductRevenueComponent implements OnInit, OnDestroy {
  private productRevenue: { [id: string]: RevenueItem };
  private sub: Subscription;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this.sub = this.reportService.orders_analytics$.subscribe((res: OrderAnalytics) => {
      this.productRevenue = res.product_revenue;
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  get chartData(): number[] {
    return this.productRevenue ? Object.values(this.productRevenue).map(rev => rev.revenue) : [0];
  }

  get chartLabels() {
    return this.productRevenue ? Object.values(this.productRevenue).map(rev => rev.name) : [''];
  }

  get chartColors() {
    return this.reportService.colors;
  }

  get ready() {
    return !!this.productRevenue;
  }
}
