import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PRICE, IProduct, Optional, ProductType, VatRate, VAT, ERROR } from 'core';
import { filter, map } from 'rxjs/operators';
import { ProductsService } from '../../products.service';
import { WizardService } from '../wizard.service';

const orderSizeValidator: ValidatorFn = (fg: FormGroup): ValidationErrors | null => {
  const min = fg.get('order_size_min');
  const max = fg.get('order_size_max');
  const batch = fg.get('batch_size');
  let valid = true;
  valid = max.value >= min.value;

  if (batch.value) {
    valid = valid && min.value >= batch.value && max.value >= batch.value;
    valid = valid && min.value % batch.value === 0;
    valid = valid && max.value % batch.value === 0;
  }
  return valid ? null : { order_size: true };
};

const numberValidator: ValidatorFn = (fc: FormControl) => {
  const isValid = fc.pristine || fc.value === '' || !isNaN(parseInt(fc.value, 10));
  return isValid ? null : { not_a_number: true };
};

const wholeNumberValidator: ValidatorFn = (fc: FormControl) => {
  const isValid = fc.pristine || fc.value === '' || (!isNaN(parseInt(fc.value, 10)) && fc.value % 1 === 0);
  return isValid ? null : { not_whole_number: true };
};
@Component({
  selector: 'app-price-structure',
  templateUrl: './price-structure.component.html',
  styleUrls: ['./price-structure.component.scss']
})
export class PriceStructureComponent implements OnInit {
  readonly ErrorStrings = ERROR;

  productPriceForm = this.fb.group(
    {
      price: ['', [Validators.required, Validators.min(0.01)]],
      vat_rate: ['', Validators.required],
      unit_size: ['', [numberValidator, Validators.min(0)]],
      unit: [''],
      batch_size: ['', [wholeNumberValidator, Validators.min(1)]],
      order_size_min: ['', [wholeNumberValidator, Validators.min(1)]],
      order_size_max: ['', [wholeNumberValidator, Validators.min(1)]]
    },
    {
      validator: orderSizeValidator
    }
  );

  private _product: IProduct;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private products: ProductsService,
    private wizard: WizardService
  ) {}

  ngOnInit() {
    this.products.currentProduct
      .pipe(
        filter(prod => prod.isPresent),
        map(prod => prod.value)
      )
      .subscribe(prod => {
        this._product = prod;
        this.setFormValues(prod);
      });

    this.productPriceForm.valueChanges
      .pipe(filter(_ => this.productPriceForm.dirty))
      .subscribe(_ => this.wizard.markDirty());

    this.productPriceForm.get('batch_size').valueChanges.subscribe(val => {
      if (val && +val > 0) {
        this.productPriceForm.get('order_size_min').setValue(val);
        this.productPriceForm.get('order_size_max').setValue(val);
      }
    });
  }

  private setFormValues(prod: IProduct) {
    const price = prod.price.getOrElse(DEFAULT_PRICE).price_excl_vat;
    const vatrate = prod.price.isPresent
      ? prod.price.value.vat_rate === 'standard'
        ? VatRate.Standard
        : VatRate.Zero
      : VatRate.Standard;

    this.productPriceForm.setValue({
      price: price,
      vat_rate: vatrate,
      unit_size: prod.unit_size,
      unit: prod.unit,
      batch_size: prod.batch_size ? prod.batch_size : null,
      order_size_min: prod.order_size.min,
      order_size_max: prod.order_size.max
    });
  }

  private getFormValues() {
    this._product.price = Optional.of({
      price_excl_vat: this.productPriceForm.get('price').value,
      vat_rate: this.productPriceForm.get('vat_rate').value === 'standard' ? VatRate.Standard : VatRate.Zero
    });

    this._product.unit_size = this.productPriceForm.get('unit_size').value;
    this._product.unit = this.productPriceForm.get('unit').value;
    this._product.batch_size = this.productPriceForm.get('batch_size').value;
    this._product.order_size = {
      min: this.productPriceForm.get('order_size_min').value,
      max: this.productPriceForm.get('order_size_max').value
    };
  }

  get price() {
    return this.productPriceForm.get('price');
  }

  get vat_rates() {
    return Object.values(VatRate);
  }

  get batch_size() {
    return this.productPriceForm.get('batch_size').value;
  }

  get vat_rate() {
    return this.productPriceForm.get('vat_rate');
  }

  get is_quote() {
    return this._product && this._product.product_type === ProductType.quote;
  }

  next() {
    this.getFormValues();
    this.products.commit(this._product);
    this.router.navigate(['../delivery'], { relativeTo: this.route });
  }

  get button_text(): 'Next' {
    return 'Next';
  }

  back() {
    this.router.navigate(['../details'], { relativeTo: this.route });
  }

  cancel() {
    this.router.navigate(['products']);
  }
}
