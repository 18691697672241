import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { ReportComponent } from './dashboard/report/report.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountComponent } from './account/account/account.component';
import { AuthGuard } from './auth/auth.guard';
import { SigninComponent } from './signin/signin.component';

const appRoutes: Routes = [
  { path: 'signin', component: SigninComponent },
  { path: 'overview', component: ReportComponent, canActivate: [AuthGuard], data: { title: 'Overview' } },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
