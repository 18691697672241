<h4>Daily Order Value</h4>
<div class="d-flex align-items-end mt-5">
  <canvas
    *ngIf="ready"
    baseChart
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="chartColors"
  ></canvas>
</div>
