import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CategoryDefinition,
  CategoryFieldType,
  DEFAULT_CATEGORY,
  Dictionary,
  ERROR,
  IProduct,
  Optional,
  ProductAttribute,
  ProductCategory,
  ProductCategoryField,
  ProductType,
  ProductUpload,
  ImageSize
} from 'core';
import { filter, map } from 'rxjs/operators';
import { FileContent } from '../../../file-upload/file-upload.component';
import { UploaderService } from '../../../uploader.service';
import { ProductsService } from '../../products.service';
import { WizardService } from '../wizard.service';
import { environment } from 'projects/merchant/src/environments/environment';

export interface Field {
  name: string;
  type: CategoryFieldType;
  control: FormControl;
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  private _product: IProduct;
  private _fields: Field[] = new Array();
  private s3ImageUploader;
  private s3PdfUploader;
  uploading = false;
  loading = false;

  selectedCategory: ProductCategory;
  private upload_fields = new Dictionary<ProductUpload>();
  readonly errors = ERROR;

  productDetailsForm = this.fb.group({
    productCategory: ['', Validators.required],
    productName: ['', [Validators.required, Validators.minLength(4)]],
    description: ['', Validators.required],
    fields: this.fb.array([this.fb.control('')]),
    attribute1: [''],
    attribute2: [''],
    shopOrClassifiedOrQuote: [''],
    stock: [0, [Validators.required, Validators.min(0)]]
  });

  constructor(
    private fb: FormBuilder,
    private productService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private wizard: WizardService,
    private upload: UploaderService
  ) {
    this.s3ImageUploader = upload.getS3Uploader(environment.cdn.bucket);
    this.s3PdfUploader = upload.getS3Uploader(environment.pdf_bucket, '.pdf');
  }

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      if (p.get('id') !== 'new') {
        this.productDetailsForm.get('productCategory').disable();
      }
    });

    this.productService.currentProduct
      .pipe(
        filter(prod => prod.isPresent),
        map(prod => prod.value)
      )
      .subscribe(prod => {
        this.setProductValues(prod);
      });

    // subscribe to selected category changes
    this.productDetailsForm.get('productCategory').valueChanges.subscribe(category => {
      if (this.categories) {
        const selected = this.categories.item(category);
        this.selectedCategory = selected;
        this.setFields(selected);
        if (this.fields && this.fields.length > 0) {
          this.productDetailsForm.get('attribute1').setValue(this.fields[0].name, { emitEvent: false });
          this.productDetailsForm.get('attribute2').setValue(this.fields[0].name, { emitEvent: false });
        }
      }
    });

    this.productDetailsForm.get('shopOrClassifiedOrQuote').valueChanges.subscribe(val => {
      if (val === ProductType.shop) {
        this.productDetailsForm.get('stock').enable();
      } else {
        this.productDetailsForm.get('stock').disable();
      }
    });

    this.productDetailsForm.valueChanges
      .pipe(filter(_ => this.productDetailsForm.dirty))
      .subscribe(_ => this.wizard.markDirty());
  }

  private setProductValues(prod: IProduct) {
    this._product = prod;
    this.selectedCategory = (this.categories && this.categories.item(this._product.category.name)) || DEFAULT_CATEGORY;
    this.setFormValue();
    this.setFields(this.selectedCategory);
  }

  get product() {
    return this._product;
  }

  private setFormValue() {
    this.productDetailsForm.patchValue({
      productCategory: this.selectedCategory.name,
      productName: this._product.name,
      description: this._product.description,
      attribute1: this._product.attributes[0],
      attribute2: this._product.attributes[1],
      shopOrClassifiedOrQuote: this._product.product_type ? this._product.product_type : ProductType.shop,
      stock: this._product.stock
    });

    if (this._product.product_type === ProductType.shop) {
      this.productDetailsForm.get('stock').enable();
    } else {
      this.productDetailsForm.get('stock').disable();
    }
  }

  get product_image() {
    if (this.product && this.product.image) {
      return this.productService.getProductImage(this.product.image, ImageSize.small);
    }
    return '';
  }

  private getFormValue() {
    if (!this._product) {
      this._product = {} as IProduct;
    }
    this._product.name = this.productDetailsForm.get('productName').value;
    this._product.description = this.productDetailsForm.get('description').value;
    this._product.category = this.selectedCategory;
    this._product.attributes = [this.attribute1, this.attribute2];

    // if shop product and not quote - should have a price field
    if (this.isQuote) {
      this._product.price = Optional.empty();
    }
    this._product.product_type = this.productType;
    this._product.fields = this.getFields();
    this._product.stock = this.productDetailsForm.get('stock').value;
  }

  private parseUrl(url: string): [string, 'https' | 'http'] {
    if (url.startsWith('https://')) {
      return [url.replace('https://', ''), 'https'];
    }
    if (url.startsWith('http://')) {
      return [url.replace('http://', ''), 'http'];
    }
    return [url, 'http'];
  }

  private setFields(selected: ProductCategory) {
    this._fields.splice(0);

    while (this.fieldControls.length !== 0) {
      this.fieldControls.removeAt(0);
    }
    if (selected.fields) {
      for (const f of selected.fields) {
        let ctrl = this.fb.control('');

        if (this._product && this._product.fields) {
          const field = this._product.fields.find(_f => _f.name === f.name);
          if (field) {
            let val: any = field.value;
            if (field.type === 'boolean') {
              val = field.value === 'true';
            }
            if (field.type === 'url') {
              const parsed = this.parseUrl(field.value);
              const url_prefix = parsed[1] === 'https' ? 'https://' : 'http://';
              val = url_prefix + parsed[0];
            }
            ctrl = this.fb.control(val);
          }
        }

        this.fieldControls.push(ctrl);

        this._fields.push({
          name: f.name,
          type: f.type,
          control: ctrl
        });
      }
    }
  }

  private getFields(): ProductAttribute[] {
    return this.fields.map(this.mapFieldToProductAttribute);
  }

  get description() {
    return this.productDetailsForm.get('description');
  }

  get productName() {
    return this.productDetailsForm.get('productName');
  }

  get attribute1(): string {
    return this.productDetailsForm.get('attribute1').value;
  }

  get attribute2() {
    return this.productDetailsForm.get('attribute2').value;
  }

  next() {
    this.getFormValue();
    this.productService.commit(this._product);
    this.router.navigate(['../pricing'], { relativeTo: this.route });
  }

  get isQuote(): boolean {
    return this.productDetailsForm.get('shopOrClassifiedOrQuote').value === ProductType.quote;
  }

  get productType() {
    return this.isQuote ? ProductType.quote : this.isShop ? ProductType.shop : ProductType.classified;
  }

  get buttonText(): 'Next' {
    return 'Next';
  }

  get fieldControls() {
    return this.productDetailsForm.get('fields') as FormArray;
  }

  get fields() {
    return this._fields;
  }

  get categories() {
    return this.productService.categories;
  }

  get isGeneral() {
    return this.selectedCategory ? this.selectedCategory.name === 'General' : false;
  }

  get attributes() {
    return this.selectedCategory ? this.selectedCategory.fields : [];
  }

  get isShop() {
    return this.productDetailsForm.get('shopOrClassifiedOrQuote').value === ProductType.shop;
  }

  get invalid() {
    return this.productDetailsForm.invalid;
  }

  get invalidAndTouched() {
    return this.productDetailsForm.invalid && this.productDetailsForm.touched;
  }

  get stock() {
    return this.productDetailsForm.get('stock');
  }

  mapFieldToProductAttribute = (f: Field): ProductAttribute => {
    const { name, type } = f;
    let value = f.control.value;

    if (f.type === 'boolean') {
      value = (!!value).toString();
    }
    if (f.type === 'url') {
      const parsed = this.parseUrl(value);
      const url_prefix = parsed[1] === 'https' ? 'https://' : 'http://';
      value = url_prefix + parsed[0];
    }
    if (this.upload_fields.containsKey(f.name)) {
      value = this.upload_fields.item(f.name).source_url;
    }
    return {
      name,
      type,
      value
    };
  };

  mapType(type: CategoryFieldType) {
    return CategoryDefinition.mapInputType(type);
  }

  isMultiSelect(type) {
    return CategoryDefinition.mapInputType(type) === 'select';
  }

  isBooleanField(type: CategoryFieldType) {
    return type === 'boolean';
  }

  cancel() {
    this.router.navigate(['products']);
  }

  handleImage(event: FileContent) {
    this.loading = true;
    this.s3ImageUploader.upload(event.file, event.content, 'products').subscribe(key => {
      this._product.image = key;
      this.loading = false;
    });
  }

  handleUploadField(field: ProductCategoryField, event) {
    this.uploading = true;
    this.s3PdfUploader.upload(event.file, event.content, 'products').subscribe(key => {
      this.upload_fields.add(field.name, {
        source_url: environment.product_data_url + '/' + key,
        title: event.file.name,
        id: key
      });
    });
  }

  uploadReady(field: ProductCategoryField): boolean {
    const status =
      this.upload_fields.containsKey(field.name) || !!this._fields.find(f => f.name === field.name).control.value;
    if (status) this.uploading = false;
    return status;
  }

  uploadSrc(field: ProductCategoryField): string {
    return this.upload_fields.containsKey(field.name)
      ? this.upload_fields.item(field.name).source_url
      : this._fields.find(f => f.name === field.name).control.value;
  }

  uploadName(field): string {
    return this.upload_fields.containsKey(field.name)
      ? this.upload_fields.item(field.name).title
      : this._fields.find(f => f.name === field.name).name;
  }
}
