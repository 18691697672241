import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderAnalytics, ReportService } from '../report.service';

@Component({
  selector: 'app-order-value',
  templateUrl: './order-value.component.html',
  styleUrls: ['./order-value.component.scss']
})
export class OrderValueComponent implements OnInit, OnDestroy {
  private _dailyOrderValue;
  private sub: Subscription;

  barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  barChartType = 'bar';
  barChartLegend = true;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this.sub = this.reportService.orders_analytics$.subscribe((res: OrderAnalytics) => {
      this._dailyOrderValue = res.order_value;
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  get chartLabels(): string[] {
    return this._dailyOrderValue ? Object.keys(this._dailyOrderValue) : [];
  }

  get chartData() {
    return this._dailyOrderValue
      ? [
          {
            data: Object.values(this._dailyOrderValue).map(d => +d),
            label: 'Order Value'
          }
        ]
      : [{}];
  }

  get chartColors() {
    return this.reportService.colors;
  }

  get ready() {
    return !!this._dailyOrderValue;
  }
}
