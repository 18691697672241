import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { NavigationModule } from '../navigation/navigation.module';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { OrderAddressComponent } from './order-address/order-address.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { OrdersComponent } from './orders/orders.component';

@NgModule({
  declarations: [
    OrderManagementComponent,
    OrdersComponent,
    OrderListComponent,
    OrderStatusComponent,
    CustomerInfoComponent,
    OrderAddressComponent
  ],
  entryComponents: [CustomerInfoComponent],

  imports: [
    CommonModule,
    FormsModule,
    NavigationModule,
    OrdersRoutingModule,
    CoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    }),
    NgbModule
  ]
})
export class OrdersModule {}
