import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  contain,
  DeliveryDetails,
  DeliveryMethod,
  DeliveryRate,
  ICollectionLocation,
  IProduct,
  Optional,
  ProductCollectionLocation,
  ProductType,
  DeliveryFeeOption,
  NOTIFICATION,
  NotificationService
} from 'core';
import { filter, map } from 'rxjs/operators';
import { LocationService } from '../../../locations/location.service';
import { ProductsService } from '../../products.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit {
  deliveryDetailsForm = this.fb.group({
    delivery_time_days: [''],
    order_or_batch: [DeliveryFeeOption.order]
  });

  allowDelivery = new FormControl(false);
  allowCollection = new FormControl(false);

  private _product: IProduct;
  private _allLocations = new Array<ICollectionLocation>();
  private _addedlocs: ProductCollectionLocation[];
  selectedLoc: ICollectionLocation;
  private _delivery: DeliveryDetails;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private products: ProductsService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private wizard: WizardService
  ) {
    this._addedlocs = new Array();
    this._delivery = new DeliveryDetails(5);
  }

  ngOnInit() {
    this.products.currentProduct
      .pipe(
        filter(prod => !prod.isEmpty),
        map(prod => prod.value)
      )
      .subscribe(prod => {
        this.setFormValue(prod);
      });

    this.locationService.locations.subscribe(locs => (this._allLocations = locs));

    this.deliveryDetailsForm.valueChanges.pipe(filter(_ => this.deliveryDetailsForm.dirty)).subscribe(val => {
      if (this._product.delivery.isPresent) {
        this._delivery.order_or_batch = val.order_or_batch;
        this._delivery.delivery_time_days = val.delivery_time_days;
        this.wizard.markDirty();
      }
    });

    this.allowCollection.valueChanges.subscribe(val => this.updateDeliveryOptions(val, DeliveryMethod.Collection));
    this.allowDelivery.valueChanges.subscribe(val => this.updateDeliveryOptions(val, DeliveryMethod.Delivery));
  }

  private updateDeliveryOptions(val: boolean, method: DeliveryMethod) {
    if (val) {
      this._product.delivery_methods.push(method);
      if (method === DeliveryMethod.Collection) {
        this._product.collection_locations = Optional.of(this._addedlocs);
      } else if (method === DeliveryMethod.Delivery) {
        this._product.delivery = Optional.of(this._delivery);
      }
    } else {
      const found = this._product.delivery_methods.findIndex(del => del === method);
      if (found > -1) this._product.delivery_methods.splice(found, 1);
      if (method === DeliveryMethod.Collection) {
        this._product.collection_locations = Optional.empty();
      } else if (method === DeliveryMethod.Delivery) {
        this._product.delivery = Optional.empty();
      }
    }
    this.products.commit(this._product);
  }

  private setFormValue(prod: IProduct) {
    this._product = prod;
    if (!this._product.delivery_methods) {
      this._product.delivery_methods = new Array();
    }
    this.allowDelivery.setValue(this.canDeliver(), { emitEvent: false });
    this.allowCollection.setValue(this.canCollect(), { emitEvent: false });

    if (prod.collection_locations.isPresent) {
      this._addedlocs = prod.collection_locations.value;
    }
    if (this._product.delivery.isPresent) {
      this._delivery = this._product.delivery.value;
      this.deliveryDetailsForm.setValue({
        delivery_time_days: this._delivery.delivery_time_days,
        order_or_batch: this._delivery.order_or_batch
      });
    }
  }

  private canDeliver() {
    return (
      this._product.delivery.isPresent ||
      contain(this._product.delivery_methods, DeliveryMethod.Delivery, (del1, del2) => del1 === del2)
    );
  }

  private canCollect() {
    return (
      this._product.collection_locations.isPresent ||
      contain(this._product.delivery_methods, DeliveryMethod.Collection, (del1, del2) => del1 === del2)
    );
  }

  get collection_locations() {
    return this._addedlocs;
  }

  get available_locations(): ICollectionLocation[] {
    return this._allLocations.filter(loc => this._addedlocs.findIndex(al => al.id === loc.id) < 0);
  }

  get invalid() {
    if (!this.allowDelivery.value && !this.allowCollection.value) return true;
    return !(this.valid_collection && this.valid_delivery);
  }

  private get valid_delivery() {
    if (!this.allowDelivery.value) {
      return true;
    }
    return this.allowDelivery.value && this._delivery.rates.length > 0;
  }

  private get valid_collection() {
    if (!this.allowCollection.value) {
      return true;
    }
    return this.allowCollection.value && this._addedlocs && this._addedlocs.length > 0;
  }

  get batch() {
    return !!this._product.batch_size;
  }

  addLocation() {
    if (this.selectedLoc) {
      this._addedlocs.push({
        id: this.selectedLoc.id,
        name: this.selectedLoc.name,
        province: this.selectedLoc.address.province
      });
      this.selectedLoc = this.available_locations[0];
    }
  }

  deleteLocation(loc) {
    this._addedlocs.splice(this._addedlocs.indexOf(loc), 1);
  }

  save() {
    this._product.collection_locations = this.allowCollection.value ? Optional.of(this._addedlocs) : Optional.empty();
    this._product.delivery = this.allowDelivery.value ? Optional.of(this._delivery) : Optional.empty();
    this.products.save(this._product).subscribe(createdProduct => {
      if (!this._product.id && createdProduct.value.id)
        //created and not updated
        this.notificationService.openSnackbar(
          { duration: 10000, data: { message: NOTIFICATION.PRODUCT_CREATED, action: 'Publish', type: 'success' } },
          () => this.products.publish(createdProduct.value.id as any).subscribe()
        );
      this.wizard.markPristine();
      this.router.navigate(['products']);
    });
  }

  back() {
    this.router.navigate(['../pricing'], { relativeTo: this.route });
  }

  cancel() {
    this.router.navigate(['products']);
  }

  get is_quote() {
    return this._product.product_type === ProductType.quote;
  }

  get rates(): DeliveryRate[] {
    if (this._product.delivery.isPresent) {
      return this._delivery.rates;
    }
    return [];
  }

  set rates(rates: DeliveryRate[]) {
    this._delivery.rates = rates;
  }
}
