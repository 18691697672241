<app-nav></app-nav>

<div>
  <div class="row">
    <div class="col"></div>
    <div class="col-md-4">
      <div class="float-right" (click)="add()">
        <label class="ac-click-label"><i class="fas fa-plus-circle fa-2x btn ac-icon-btn"></i>Add Location</label>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
