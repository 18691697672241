import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProduct, ProductAttribute, ProductStatus, ProductType, ProductUtil, ProductSummary, ImageSize } from 'core';
import { UserService } from '../../user.service';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss']
})
export class ProductSummaryComponent implements OnInit {
  private _prod: IProduct;
  private summary: ProductSummary;

  buttons = {
    shop: {
      icon: 'fa-shopping-cart',
      label: 'BUY'
    },
    classified: {
      icon: 'fa-eye',
      label: 'VIEW'
    },
    quote: {
      icon: 'fa-edit',
      label: 'QUOTE'
    }
  };

  private getAttribute = ProductUtil.getAttribute;
  constructor(
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductsService
  ) {}

  ngOnInit() {}

  @Input()
  set product(val: IProduct) {
    if (val) {
      this._prod = val;
      this.summary = new ProductSummary(this._prod);
    }
  }

  get product() {
    return this._prod;
  }

  edit() {
    this.router.navigate(['edit', this.product.id], { relativeTo: this.route });
  }

  get product_image() {
    return this.product ? this.productService.getProductImage(this.product.image, ImageSize.small) : '';
  }

  get merchant() {
    return this.user.company;
  }

  get price() {
    return this.product.price.isEmpty ? 'Request Price' : this.product.price.value.price_excl_vat;
  }

  get price_unit() {
    if (this.product.unit_size) return '/ ' + this.product.unit_size + this.product.unit;
  }

  get attribute1(): ProductAttribute | string {
    return this.getAttribute(this.product, 0);
  }

  get attribute2(): ProductAttribute | string {
    return this.getAttribute(this.product, 1);
  }

  get button() {
    if (this.product.product_type === ProductType.classified) {
      return this.buttons.classified;
    }
    if (this.product.product_type === ProductType.shop) {
      return this.buttons.shop;
    }
    if (this.product.product_type === ProductType.quote) {
      return this.buttons.quote;
    }
    return this.buttons.classified;
  }

  get isShop() {
    return this.product.product_type === ProductType.shop;
  }

  get isQuote() {
    return this.product.product_type === ProductType.quote;
  }

  get collection() {
    return this.summary ? this.summary.collection : false;
  }

  get delivery() {
    if (this.product.delivery.isPresent && this.product.delivery.value.delivery_time_days) {
      return this.product.delivery.value.delivery_time_days;
    }
    return false;
  }

  get pending() {
    return this.product.status === ProductStatus.pending;
  }

  get draft() {
    return this.product.status === ProductStatus.draft;
  }

  get published() {
    return this.product.status === ProductStatus.publish;
  }

  get status() {
    if (this.pending) return 'Pending';
    if (this.draft) return 'Draft';
  }

  get stock() {
    return this.product.stock ? this.product.stock : 0;
  }

  get out_of_stock() {
    return this.stock === 0;
  }
}
