import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductWizardComponent } from './product-wizard/product-wizard.component';
import { ProductDetailsComponent } from './product-wizard/product-details/product-details.component';
import { PriceStructureComponent } from './product-wizard/price-structure/price-structure.component';
import { DeliveryDetailsComponent } from './product-wizard/delivery-details/delivery-details.component';
import { AuthGuard } from '../auth/auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';

const locationRoutes: Routes = [
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductsListComponent
      },
      {
        path: 'edit',
        component: ProductWizardComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: ':id',
            children: [
              {
                path: 'details',
                component: ProductDetailsComponent
              },
              {
                path: 'pricing',
                component: PriceStructureComponent
              },
              {
                path: 'delivery',
                component: DeliveryDetailsComponent
              },
              { path: '', redirectTo: 'details', pathMatch: 'full' }
            ]
          },
          { path: '', redirectTo: 'new/details', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(locationRoutes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule {}
