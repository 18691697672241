<div class="col">
  <div class="form-group row">
    <input
      class="form-control col-md-8 col-lg-3 col-sm-9 col-12 mr-2 mb-2"
      placeholder="Name"
      name="name"
      [(ngModel)]="name"
    />
    <input
      class="form-control col-md-8 col-lg-3 col-sm-9 col-12 mr-2 mb-2"
      placeholder="Contact Number"
      name="contactNumber"
      [(ngModel)]="contactNumber"
    />
    <input
      class="form-control col-md-8 col-lg-4 col-sm-9 col-12 mb-2"
      placeholder="Label e.g. Province"
      name="label"
      [(ngModel)]="label"
    />
    <div class="col col-sm-6 col-md-4 col-lg">
      <button
        class="btn ac-btn-primary btn-block mb-2"
        (click)="addContact(name, contactNumber, label)"
        [disabled]="!(name && contactNumber && label)"
      >
        Add
      </button>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table">
    <thead>
      <th>Name</th>
      <th>Contact Number</th>
      <th>Label</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let contact of contacts">
        <td>{{ contact.name }}</td>
        <td>{{ contact.number }}</td>
        <td>{{ contact.label }}</td>
        <td><i class="fas fa-trash-alt icon-btn" (click)="deleteContact(contact)"></i></td>
      </tr>
    </tbody>
  </table>
</div>
