import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Address,
  Alert,
  AlertType,
  CollectionLocation,
  ICollectionLocation,
  OperatingHours,
  NOTIFICATION,
  ERROR,
  CityLookupService
} from 'core';
import { map, switchMap, tap } from 'rxjs/operators';
import { LocationService } from '../location.service';

const operatingHoursValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const hourString = control.value;
  try {
    OperatingHours.parse(hourString);
    return null;
  } catch (e) {
    return { operatingHours: true };
  }
};

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {
  private _location: ICollectionLocation;
  private id: string;
  alert: Alert;
  notification = NOTIFICATION;
  error = ERROR;

  locationForm = this.fb.group({
    locationName: ['', Validators.required],
    addressLine1: [''],
    addressLine2: [''],
    city: ['', Validators.required],
    province: ['', Validators.required],
    description: [''],
    operatingHours: ['', [Validators.required, operatingHoursValidator]]
  });

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        tap(params => (this.id = params.get('id'))),
        switchMap(_ => this.locationService.locations),
        map(_ => this.locationService.getLocation(this.id))
      )
      .subscribe(loc => {
        this._location = loc;

        if (loc) {
          this.setFormValue();
        }
        if (this.alert) {
          this.alert.showAlert = false;
        }
      });
  }

  private setFormValue() {
    this.locationForm.setValue({
      locationName: this._location.name,
      addressLine1: this._location.address.address_1,
      addressLine2: this._location.address.address_2,
      city: this._location.address.city,
      province: this._location.address.province ? this._location.address.province : null,
      description: this._location.description,
      operatingHours: OperatingHours.hoursToString(this._location.hours)
    });
  }

  private getFormValue() {
    this._location.name = this.locationForm.get('locationName').value;
    this._location.address = new Address(
      this.locationForm.get('addressLine1').value,
      this.locationForm.get('addressLine2').value,
      this.locationForm.get('city').value,
      '',
      this.locationForm.get('province').value
    );
    this._location.description = this.locationForm.get('description').value;
    this._location.hours = OperatingHours.parse(this.operatingHours.value);
  }

  createNewLocation() {
    this._location = new CollectionLocation();
    this.locationForm.reset();
  }

  get provinces() {
    return CityLookupService.PROVINCES;
  }

  get location() {
    return this._location;
  }

  get latitude() {
    return this._location.geo_location.latitude;
  }

  get longitude() {
    return this._location.geo_location.longitude;
  }

  onMarkerDragEnd($event) {
    this._location.geo_location.latitude = $event.coords.lat;
    this._location.geo_location.longitude = $event.coords.lng;
    this.locationForm.markAsDirty();
  }

  save() {
    this.getFormValue();
    try {
      let obs;
      if (this._location.id) {
        obs = this.locationService.update(this._location);
      } else {
        obs = this.locationService.add(this._location);
      }
      obs.subscribe(() => {
        this.router.navigate(['/locations']);
      });
    } catch (e) {
      this.alert = new Alert(AlertType.danger, e.message);
      this.alert.showAlert = true;
    }
  }

  get locationName() {
    return this.locationForm.get('locationName');
  }

  get city() {
    return this.locationForm.get('city');
  }

  get province() {
    return this.locationForm.get('province');
  }

  get operatingHours() {
    return this.locationForm.get('operatingHours');
  }

  get pristineOrInvalid() {
    return this.locationForm.invalid || this.locationForm.pristine;
  }

  get invalidAndTouched() {
    return this.locationForm.invalid && this.locationForm.touched;
  }

  get showNotification() {
    return this.id && this.id !== 'new' && this.locationForm.dirty;
  }

  cancel() {
    this.locationService.fetchLocations();
    this.router.navigate(['/locations']);
  }
}
