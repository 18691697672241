<div class="row">
  <div class="col-md-6">
    <h4>Commissions Paid & Due</h4>
    <table class="table">
      <tbody>
        <tr>
          <th>Paid</th>
          <td>{{ commissions?.paid | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
        </tr>
        <tr>
          <th>Due</th>
          <td>{{ commissions?.due | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-6">
    <div class="d-flex align-items-center flex-column">
      <canvas
        *ngIf="ready"
        baseChart
        [data]="chartData"
        [labels]="chartLabels"
        chartType="pie"
        [colors]="chartColors"
      ></canvas>
    </div>
  </div>
</div>
