import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, AlertType, OrderDetails, OrderUpdateRequest } from 'core';
import { map, switchMap, tap } from 'rxjs/operators';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss']
})
export class OrderManagementComponent implements OnInit {
  private _order: OrderDetails;
  alert: Alert;
  private _id: string;

  constructor(private orderService: OrderService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map(params => params.get('id')),
        tap(id => (this._id = id)),
        switchMap(id => this.orderService.getOrderById(+id))
      )
      .subscribe(
        order => {
          if (order) {
            this._order = order;
          } else {
            this.alert = new Alert(AlertType.warning, 'Order #' + this._id + ' not found');
          }
        },
        _ => {
          this.alert = new Alert(AlertType.warning, 'Order #' + this._id + ' not found');
        }
      );
  }

  requestUpdate(request: OrderUpdateRequest) {
    this.orderService.updateOrder(request).subscribe(
      order => {
        this._order = order;
        this.orderService.getOrders();
      },
      error => {
        this.alert = new Alert(AlertType.danger, 'An error has occured and your changes have not been saved.');
      }
    );
  }

  get order(): OrderDetails {
    return this._order;
  }
}
