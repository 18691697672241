import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { HeaderComponent } from './header/header.component';
import { LocationsModule } from './locations/locations.module';
import { OrdersModule } from './orders/orders.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductsModule } from './products/products.module';
import { SigninComponent } from './signin/signin.component';
import { LoadSpinnerInterceptor } from './utils/load-spinner.interceptor';
import { AutofocusDirective } from './autofocus.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SigninComponent,
    PageNotFoundComponent,
    ConfirmDeleteComponent,
    AutofocusDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    NgbAlertModule,
    CoreModule,
    AccountModule,
    DashboardModule,
    OrdersModule,
    ProductsModule,
    LocationsModule,
    MatFormFieldModule,
    AppRoutingModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadSpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'api_url',
      useValue: environment.api_url + environment.api_path
    },
    {
      provide: 'cdn_config',
      useValue: {
        bucket: environment.cdn.bucket,
        url: environment.cdn.url
      }
    }
  ],
  entryComponents: [ConfirmDeleteComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
