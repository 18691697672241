<div class="modal-header">
  <h4 class="modal-title">Please note</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    {{ notifications.PRODUCT_LOCATIONS_NOT_UPDATED }}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
</div>
