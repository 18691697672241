<div class="row mb-3">
  <div class="col">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <th>Name</th>
          <th>Address</th>
          <th>City/Town</th>
          <th>Operating Hours</th>
          <th></th>
        </thead>
        <tbody>
          <tr
            *ngFor="let location of locations"
            (click)="onSelect(location)"
            class="location"
            [class.table-active]="location === selectedLocation"
          >
            <td>{{ location.name }}</td>
            <td>
              {{ location.address.address_1 }}{{ location.address.address_2 ? ', ' + location.address.address_2 : '' }}
            </td>
            <td>{{ location.address.city }}</td>
            <td>{{ hoursToString(location.hours) }}</td>
            <td><i class="fas fa-trash-alt icon-btn" (click)="delete(location, $event)"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
