// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://api.dev.agrichecker.com',
  blog_url: 'http://blog.agrichecker.com/blog',
  api_path: '/wp-json',
  shop_url: 'https://www.dev.agrichecker.com',
  merchant_url: 'https://merchant.dev.agrichecker.com',
  about_us_url: 'http://blog.agrichecker.com/',
  product_data_url: 'https://product.data.agrichecker.com',
  cdn: {
    url: 'https://cdn.agrichecker.com',
    bucket: 'cdn.dev.agrichecker.com'
  },
  pdf_bucket: 'product.data.agrichecker.com',
  region: 'us-east-2',
  id_pool_id: 'us-east-2:0cf44cbc-df4f-4f84-a8bf-8d08fcb844ef'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
