import { Injectable } from '@angular/core';
import {
  Address,
  ApiService,
  CollectionLocation,
  Dictionary,
  ICollectionLocation,
  IDictionary,
  Namespace,
  Endpoints
} from 'core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export enum LocationEventType {
  Create,
  Update
}

interface LocationEvent {
  type: LocationEventType;
  location?: ICollectionLocation;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _locations: IDictionary<ICollectionLocation> = new Dictionary<ICollectionLocation>();
  private locations$: Subject<Array<ICollectionLocation>>;
  locationEvents$: Subject<LocationEvent> = new Subject();

  constructor(private api: ApiService) {}

  get locations(): Observable<ICollectionLocation[]> {
    if (!this.locations$) {
      this.locations$ = new ReplaySubject<Array<ICollectionLocation>>(1);
      this.fetchLocations();
      return this.locations$;
    }
    return this.locations$;
  }

  get locationsData() {
    return this._locations;
  }

  getLocation(id) {
    if (id === 'new') {
      return new CollectionLocation();
    }
    return this._locations.item(id);
  }

  fetchLocations() {
    this.api
      .get(Namespace.ac, Endpoints.merchant_locations)
      .pipe(
        map(locations => {
          if (locations && locations.length) {
            const locs = (<Array<ICollectionLocation>>locations).map(l => {
              l.address = new Address(
                l.address.address_1,
                l.address.address_2,
                l.address.city,
                l.address.postal_code,
                l.address.province
              );
              return l;
            });
            return locs;
          }
          return [];
        }),
        tap(locs => locs.forEach(loc => this._locations.add(loc.id, loc)))
      )
      .subscribe(loc => this.locations$.next(loc));
  }

  add(location: ICollectionLocation) {
    if (this._locations.values().find(loc => loc.name === location.name)) {
      throw new Error('A collection location with the name ' + location.name + '  already exists');
    }
    if (this._locations.values().find(loc => loc.address.equals(location.address))) {
      throw new Error(
        'A collection location with the address ' +
          location.address.address_1 +
          ', ' +
          location.address.city +
          ' already exists'
      );
    }
    return this.api.post(Namespace.ac, Endpoints.merchant_locations, location).pipe(tap(_ => this.fetchLocations()));
  }

  update(location: ICollectionLocation) {
    const ep = Endpoints.merchant_locations + '/' + location.id;
    return this.api.put(Namespace.ac, ep, location);
  }

  delete(id) {
    const ep = Endpoints.merchant_locations + '/' + id;
    this._locations.remove(id);
    return this.api.delete(Namespace.ac, ep).pipe(tap(res => this.fetchLocations()));
  }

  public getCollectionLocationById(id: string): Observable<CollectionLocation> {
    return this.api.get(Namespace.ac, Endpoints.merchant_locations + '/' + id).pipe(
      map(res => {
        const loc = { ...res } as CollectionLocation;
        loc.hours = res.hours;
        return loc;
      })
    );
  }
}
