import { Component, OnInit } from '@angular/core';
import { AccountStatus, NotificationService, AlertType, SnackBarData } from 'core';
import { map } from 'rxjs/operators';
import { Alert } from 'core';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  private statusIcons = {
    Active: 'fas fa-check-circle status-active',
    Declined: 'fas fa-times-circle status-declined',
    Pending: 'fas fa-minus-circle status-pending'
  };

  constructor(private userService: UserService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.userService.notifications$
      .pipe(
        map(alert => {
          return {
            data: {
              type: alert.type === AlertType.danger ? 'error' : 'info',
              message: alert.message
            } as SnackBarData
          };
        })
      )
      .subscribe(data => {
        this.notificationService.openSnackbar(data);
      });
  }

  get statusIconClass() {
    return this.statusIcons[this.status];
  }

  get status() {
    return this.userService.profileData ? this.userService.profileData.status : AccountStatus.pending;
  }
}
