import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap, last, first } from 'rxjs/operators';
import { ApiService, Namespace, OrderDetails, OrderUpdateRequest, Endpoints, OrderSummary, Optional } from 'core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _orders: OrderDetails[];
  private _orders$: Subject<OrderDetails[]> = new Subject();

  constructor(private api: ApiService) {
    this._orders$.subscribe(orders => (this._orders = orders));
  }

  getOrders(
    _last = 10,
    filter?: (order: OrderDetails, i: number, ar: OrderDetails[]) => boolean
  ): Observable<OrderDetails[]> {
    this.api
      .get(Namespace.ac, Endpoints.merchant_orders)
      .pipe(
        first(),
        map((orders: OrderDetails[]) => orders.map(o => this.mapOrder(o)))
      )
      .subscribe(orders => this._orders$.next(orders));

    return filter ? this._orders$.pipe(map((orders: OrderDetails[]) => orders.filter(filter))) : this._orders$;
  }

  getOrderById(id: number): Observable<OrderDetails> {
    if (this._orders && this._orders.length > 0) {
      if (this._orders.find(order => order.id === id)) {
        return of(this._orders.find(order => order.id === id));
      }
    }
    return this.api
      .get(Namespace.ac, Endpoints.merchant_orders + '/' + id)
      .pipe(map((order: OrderDetails) => this.mapOrder(order)));
  }

  updateOrder(req: OrderUpdateRequest): Observable<OrderDetails> {
    return this.api.put(Namespace.ac, Endpoints.merchant_orders + '/' + req.id, req).pipe(
      first(),
      tap(updatedOrder => {
        this._orders$.next(
          this._orders.map(order => (order.id === updatedOrder.id ? this.mapOrder(updatedOrder) : order))
        );
      })
    );
  }

  private mapOrder(order: OrderDetails): OrderDetails {
    order.order = new OrderSummary(order.order.items, order.order.delivery_total, order.order.delivery_vat);

    return order;
  }
}
