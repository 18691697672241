<div>
  <input class="form-control-file" id="uploadFile" type="file" [accept]="accept" (change)="readFile($event)" />
</div>
<div *ngIf="error" class="text-danger">
  <div *ngIf="errors.hasError('size_limit_exceeded')">
    The size of the selected file exceeds the limit of {{ size_limit.toUpperCase() }}
  </div>
  <div *ngIf="errors.hasError('read_error')">
    {{ errors.errorMessage('read_error') }}
  </div>
  <div *ngIf="errors.hasError('multiple_files')">
    Multiple files selected. You can only upload a single file.
  </div>
</div>
