import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { OrderDetails } from 'core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  private _orders: OrderDetails[] = [];
  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.orderService.getOrders().subscribe((recentOrders: OrderDetails[]) => {
      this._orders = recentOrders;
    });
  }

  get orders(): OrderDetails[] {
    return this._orders;
  }
}
