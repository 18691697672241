import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderDetails, OrderStatus } from 'core';
import { Observable, Subscription } from 'rxjs';
import { OrderService } from '../../orders/order.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-recentorders',
  templateUrl: './recentorders.component.html',
  styleUrls: ['./recentorders.component.scss']
})
export class RecentordersComponent implements OnInit, OnDestroy {
  private _recentOrders$: Observable<OrderDetails[]>;
  private sub: Subscription;

  constructor(private orderService: OrderService) {
    this._recentOrders$ = this.orderService.getOrders(
      10,
      order => ![OrderStatus.failed, OrderStatus.cancelled].find(status => order.status === status)
    );
  }

  ngOnInit() {
    this.sub = this._recentOrders$.subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get recentOrders() {
    // the last 10 orders received
    return this._recentOrders$;
  }
}
