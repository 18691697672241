<div class="form-group">
  <label for="uploadFile">Upload Company Logo:</label>
  <div class="row">
    <div class="col-md-2">
      <div class="mb-3">
        <img id="merchant-logo" class="img-thumbnail rounded mr-2" [src]="logo" alt="Company Logo" />
      </div>
    </div>

    <div class="col-md-6 col-5">
      <div *ngIf="loading" class="spinner-border my-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <app-file-upload
        (src)="handleImage($event)"
        [accept]="'image/png,image/jpg,image/jpeg'"
        [size_limit]="'1M'"
      ></app-file-upload>
    </div>
  </div>
</div>

<form [formGroup]="accountForm" (ngSubmit)="save()">
  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label for="compName">Company Name:</label>
      <input id="compName" class="form-control" type="text" name="companyName" formControlName="companyName" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label for="weburl">Website:</label>
      <input
        id="weburl"
        class="form-control"
        type="URL"
        name="websiteUrl"
        formControlName="website"
        pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
      />
      <div *ngIf="accountForm.get('website').invalid && accountForm.get('website').dirty" class="mt-2">
        <mat-error>{{ errors.INVALID_URL }}</mat-error>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label for="tsandcs">Terms and Conditions</label>
      <ng-template #tipContent
        >To update your customer Terms and Conditions please email <strong>{{ adminEmail }}</strong
        >.
      </ng-template>
      <textarea
        id="tsandcs"
        name="tsandcs"
        class="form-control"
        name="tsandcs"
        formControlName="tsAndCs"
        readonly
        [ngbTooltip]="tipContent"
        placement="top"
        triggers="click"
      ></textarea>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label for="email">Email Address:</label>
      <input id="email" class="form-control" type="text" name="email" formControlName="email" readonly />
    </div>
  </div>

  <div class="form-group">
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        name="passwordCheck"
        id="updatePassword"
        formControlName="updatePassword"
      />
      <label class="form-check-label" for="updatePassword">Update password</label>
    </div>
    <div *ngIf="updatePassword">
      <div class="form-group row">
        <div class="col-lg-6 col-md-8 col-12">
          <label>Current Password:</label>
          <input class="form-control" type="password" formControlName="oldPassword" required />
          <div *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)" class="mt-2">
            <mat-error *ngIf="oldPassword.hasError('required')">
              {{ errors.REQUIRED_CURRENT_PASSWORD }}
            </mat-error>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-lg-6 col-md-8 col-12">
          <label>New Password:</label>
          <input class="form-control" type="password" formControlName="newPassword" required minlength="6" />
          <div *ngIf="accountForm.invalid && (newPassword.dirty || newPassword.touched)" class="mt-2">
            <mat-error *ngIf="newPassword.hasError('minlength')">
              {{ errors.SHORT_PASSWORD }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6 col-12">
          <label>Confirm Password:</label>
          <input class="form-control" type="password" formControlName="confirmPassword" required />
          <div *ngIf="accountForm.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="mt-2">
            <mat-error *ngIf="accountForm.hasError('passwordMatch')">
              {{ errors.NON_MATCHING_PASSWORDS }}
            </mat-error>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-8 col-12">
      <div class="row">
        <div class="col-0 col-md"></div>
        <div class="col-6 col-md-4 col-lg-3">
          <button
            class="btn btn-block ac-btn-secondary"
            (click)="cancel()"
            [disabled]="accountForm.pristine"
            type="reset"
          >
            CANCEL
          </button>
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <button
            class="btn btn-block ac-btn-primary"
            [disabled]="accountForm.invalid || accountForm.pristine"
            type="submit"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
