<div class="row">
  <div class="col-md" style="overflow-x:auto;">
    <div class="table-responsive">
      <table class="table table-bordered table-hover order-list-table">
        <thead>
          <tr>
            <th>ORDER NUMBER:</th>
            <th>DATE:</th>
            <th>CUSTOMER NAME:</th>
            <th>TOTAL:</th>
            <th>VAT:</th>
            <th>PAYMENT METHOD:</th>
            <th>STATUS:</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let order of orders"
            class="cursor-pointer"
            [ngClass]="{ 'table-active': order.id === selected_order?.id }"
            (click)="selectOrder(order)"
          >
            <td>{{ order.order_number }}</td>
            <td>{{ order.date_created | date: 'EE d MMM y' }}</td>
            <td>
              <a
                ><span (click)="open(order)" class="link">{{ order.customer.name }}</span></a
              >
            </td>
            <td>{{ order.order.total | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
            <td>{{ order.order.total_vat | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
            <td>{{ order.payment_method }}</td>
            <td class="text-capitalize">{{ order.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="orders?.length == 0" class="col-md my-5">
      <h5>You have not received any orders yet.</h5>
    </div>
  </div>
</div>
