import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, zip } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { CommissionSummary } from '../model/model';
import { ApiService, Namespace, Endpoints } from 'core';

interface OrderItem {
  name: string;
  qty: number;
}

export const COLOR_SCHEME = ['#8cc640', '#709e33', '#547626', '#04512c', '#466320', '#384f19'];

export interface RevenueItem {
  name: string;
  revenue: number;
}

export interface OrderAnalytics {
  order_value: { [date: string]: number };
  top_products: { [id: string]: OrderItem };
  product_revenue: { [id: string]: RevenueItem };
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  orders_analytics$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private api: ApiService) {}

  loadOrderAnalytics(): Observable<OrderAnalytics> {
    this.api
      .get(Namespace.ac, Endpoints.analytics)
      .pipe(
        tap(res => console.log(res)),
        filter(res => !!res.product_revenue)
      )
      .subscribe(res => this.orders_analytics$.next(res));
    return this.orders_analytics$;
  }

  get commissions(): Observable<CommissionSummary> {
    const paid = this.accumulatedCommissions('paid');
    const due = this.accumulatedCommissions('due');

    return zip(paid, due).pipe(map(val => new CommissionSummary(val[0], val[1])));
  }

  private accumulatedCommissions(status: string): Observable<number> {
    return this.api.get(Namespace.ac, Endpoints.commissions).pipe(
      map((coms: Array<any>) => {
        if (coms && coms.length) {
          return coms
            .filter(com => com.status === status)
            .map(com => +com.total_due)
            .reduce((acc, val) => acc + val, 0);
        }
        return 0;
      })
    );
  }

  get colors() {
    return [
      {
        backgroundColor: COLOR_SCHEME
      }
    ];
  }
}
