import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = this.auth.authToken
      ? next.handle(
          req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + this.auth.authToken
            }
          })
        )
      : next.handle(req);

    return request.pipe(
      catchError(err => {
        if (err.status === 403 || err.status === 401) {
          this.auth.logout();
        }
        return throwError(err);
      })
    );
  }
}
