<hr />
<div class="row">
  <div class="col-5">
    <div class="row">
      <div class="col-md-4">
        <label><strong>Status:</strong></label>
      </div>
      <div class="col-md-8">
        <a (click)="edit_status = !edit_status"
          ><label class="badge badge-pill badge-secondary">{{ status }}</label></a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-8" *ngIf="edit_status || is_status_dirty">
        <select [(ngModel)]="selected_status" class="form-control" type="text" name="status">
          <option *ngFor="let status of status_list">{{ status }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-5" *ngIf="for_delivery">
    <div class="row">
      <div class="col-md-4">
        <label><strong>Delivery Date:</strong></label>
      </div>
      <div class="col-md-8">
        <a (click)="setDatePicker()" [ngbPopover]="test" placement="bottom" [autoClose]="false"
          ><label class="badge badge-pill badge-secondary">{{ delivery_date | date: 'EE d MMM y' }}</label></a
        >
      </div>
    </div>
    <div class="row">
      <ng-template #test>
        <ngb-datepicker
          #dp
          name="datepicker"
          [(ngModel)]="new_delivery_date"
          (navigate)="date = $event.next"
          [minDate]="min_date"
          [maxDate]="max_date"
        ></ngb-datepicker>
      </ng-template>
    </div>
  </div>
  <div class="col-md-2 col-4 d-flex">
    <button [disabled]="!is_dirty" class="btn ac-btn-primary col-md-12 mt-auto" (click)="save()">Save</button>
  </div>
</div>
<hr />
