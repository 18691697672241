import { Injectable } from '@angular/core';
import {
  AccountStatus,
  Alert,
  AlertType,
  ApiService,
  Contact,
  CustomerProfile,
  Endpoints,
  IAddress,
  ImageService,
  ImageSize,
  MerchantProfile,
  Namespace
} from 'core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

class LetterAvatar {
  dataURI: string;
  name: string;
  size: number;

  constructor(name, size) {
    this.name = name;
    this.size = size;
  }

  render() {
    this.name = this.name || '';
    this.size = this.size || 60;
    const d = document;
    const w = window;

    const colours = [
      '#1abc9c',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#2c3e50',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#ecf0f1',
      '#95a5a6',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d'
    ];

    const nameSplit = String(this.name)
      .toUpperCase()
      .split(' ');
    let initials, charIndex, colourIndex, canvas, context;

    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }

    if (w.devicePixelRatio) {
      this.size = this.size * w.devicePixelRatio;
    }

    charIndex = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64;
    colourIndex = charIndex % 20;
    canvas = d.createElement('canvas');
    canvas.width = this.size;
    canvas.height = this.size;
    context = canvas.getContext('2d');

    context.fillStyle = colours[colourIndex - 1];
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = Math.round(canvas.width / 2) + 'px Arial';
    context.textAlign = 'center';
    context.fillStyle = '#FFF';
    context.fillText(initials, this.size / 2, this.size / 1.5);

    this.dataURI = canvas.toDataURL();
    canvas = null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private profile$: ReplaySubject<MerchantProfile>;
  private _profile: MerchantProfile;
  private _logo: string;
  notifications$: Subject<Alert>;
  private _icon: string;

  constructor(private api: ApiService, private imgService: ImageService) {
    this.notifications$ = new Subject();
  }

  private profileRequest() {
    return this.api.get(Namespace.ac, Endpoints.merchant_profile).pipe(
      map(data => {
        const profile = data as MerchantProfile;
        profile.status = profile.status ? profile.status : AccountStatus.pending;
        return profile;
      }),
      tap((data: MerchantProfile) => {
        this._profile = data;
        this._logo = data.logo;
      })
    );
  }

  fetchProfile() {
    if (!this.profile$) {
      this.profile$ = new ReplaySubject(1);
    }

    this.profileRequest().subscribe(p => this.profile$.next(p));
  }

  get profile(): Observable<MerchantProfile> {
    // get the current user details...
    if (!this.profile$) {
      this.fetchProfile();
    }
    return this.profile$;
  }

  get is_pending() {
    return this._profile.status === AccountStatus.pending;
  }

  getLogo(size: ImageSize) {
    if (this.profileData && this.profileData.id) {
      const logo = this.imgService.getImage(this._logo, size);
      return logo ? logo : this.createLogo();
    }
    return this.createLogo();
  }

  createLogo(): string {
    let src_url = '';
    if (this._icon) {
      src_url = this._icon;
    }
    if (this._profile && !this._icon) {
      this._icon = this.createIcon(this._profile.company);
      src_url = this._icon;
    }
    return src_url;
  }

  get company() {
    return this._profile ? this._profile.company : '';
  }

  get profileData(): MerchantProfile {
    return this._profile;
  }

  private createIcon(name: string): string {
    const avatar = new LetterAvatar(name, 100);
    avatar.render();
    return avatar.dataURI;
  }

  /**
   *
   * @param message Warnign message that should be appended to Your account is pending approval:
   */
  pendingAccountNotification(message: string) {
    this.notifications$.next(new Alert(AlertType.warning, 'Your account is pending approval: ' + message));
  }

  updateProfile(name: string, website: string) {
    if (this.is_pending) {
      this.pendingAccountNotification('Cannot update profile');
      return;
    }
    this._profile.company = name;
    this._profile.website = website;
    this.commit(Endpoints.merchant_profile);
  }

  updatePassword(old_password: string, new_password: string) {
    this._profile.old_password = old_password;
    this._profile.new_password = new_password;
  }

  updateLogo(file_key: string | boolean): boolean {
    if (this.is_pending) {
      this.pendingAccountNotification('Cannot update profile');
      return;
    }
    if (typeof file_key !== 'boolean') {
      this._logo = file_key;
      this._profile.logo = this._logo;
      return true;
    }
    return false;
  }

  updateBillingInfo(billing: IAddress, tel_number?: string, vat_number?: string) {
    if (this.is_pending) {
      this.pendingAccountNotification('Cannot update Billing Address');
      return;
    }
    this._profile.address = billing;
    if (tel_number) this._profile.tel = tel_number;
    if (vat_number) this._profile.vat_no = vat_number;

    this.commit(Endpoints.merchant_billing);
  }

  private commit(endpoint: string, data?: any) {
    const body = data ? data : this._profile;
    console.log(body);
    this.api.post(Namespace.ac, endpoint, body).subscribe(
      (res: MerchantProfile) => {
        this.fetchProfile();
        let alert;
        if (res.password_changed && typeof res.password_changed !== 'boolean' && (<any>res.password_changed).errors) {
          alert = new Alert(
            AlertType.warning,
            'Password could not be updated - make sure that your current password is correct'
          );
        } else if (typeof res.password_changed === 'boolean' && res.password_changed) {
          alert = new Alert(AlertType.info, 'Password updated successfully');
        } else {
          alert = new Alert(AlertType.info, 'Profile updated successfully');
        }

        this.notifications$.next(alert);
      },
      err => {
        console.log(err);
        const alert = new Alert(AlertType.danger, 'Something happened and your profile could not be updated');
        this.notifications$.next(alert);
      }
    );
  }

  get contacts(): Contact[] {
    return this._profile ? this._profile.contacts : [];
  }

  addContact(contact: Contact) {
    if (this.is_pending) {
      this.pendingAccountNotification('Cannot add contact');
      return;
    }
    this._profile.contacts.push(contact);
    this.commit(Endpoints.merchant_contacts, contact);
  }

  deleteContact(id: number) {
    this._profile.contacts.splice(
      this.contacts.findIndex(c => c.id === id),
      1
    );

    this.api.delete(Namespace.ac, Endpoints.merchant_contacts + '/' + id).subscribe(
      res => this.fetchProfile(),
      err => console.log(err)
    );
  }

  getCustomerById(id: number): Observable<CustomerProfile> {
    return this.api.get(Namespace.ac, Endpoints.customer_profile + '/' + id);
  }
}
