<div class="row justify-content-md-center">
  <div class="col-md-6">
    <form class="mb-5" name="login" [ngClass]="{ 'mt-2': loginError, 'mt-5': !loginError }" #loginForm="ngForm">
      <div class="form-group">
        <label>Email</label>
        <input
          class="form-control"
          type="email"
          placeholder="Email"
          [(ngModel)]="username"
          name="email"
          #email="ngModel"
          required
        />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="mt-2">
          <mat-error *ngIf="email.hasError('required')">{{ errors.REQUIRED_EMAIL }}</mat-error>
        </div>
      </div>
      <div class="form-group">
        <label>Password</label>
        <input class="form-control" type="password" [(ngModel)]="password" name="password" #secret="ngModel" required />
        <div *ngIf="secret.invalid && (secret.dirty || secret.touched)" class="mt-2">
          <mat-error *ngIf="secret.hasError('required')">{{ errors.REQUIRED_PASSWORD }}</mat-error>
        </div>
      </div>
      <ngb-alert [type]="alert?.type" *ngIf="alert?.showAlert" (close)="alert.showAlert = false" [dismissible]="true">
        {{ alert.message }}
        <a *ngIf="is_customer" [href]="SHOP_URL" target="_blank"><strong>here</strong></a></ngb-alert
      >
      <div class="my-2 float-right">
        <a [href]="links.forgot_password">Forgot password?</a>
      </div>
      <div class="form-group">
        <div class="col-md-4 mx-0 px-0 my-2">
          <button
            class="btn btn-primary btn-block ac-btn-primary"
            type="submit"
            (click)="login(username, password)"
            [disabled]="loginForm.form.invalid"
          >
            Sign In
          </button>
        </div>
        <a [href]="links.register">Don't have an account? Start selling on Agrichecker.</a>
      </div>
    </form>
  </div>
</div>
