<h6>Delivery Rates</h6>
<ngb-alert type="info" [dismissible]="false">
  {{
    is_quote
      ? 'Specify for which areas delivery rates can be quoted.  Either all provinces (i.e nationwide), per province or per municipality'
      : 'Specify delivery rates per area.  Rates can be either for all provinces (i.e nationwide), per province or per municipality'
  }}</ngb-alert
>
<form class="form-group" [formGroup]="deliveryRatesForm">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <th>Province</th>
        <th>Municipality</th>
        <th>Rate</th>
        <th></th>
        <tr [formGroup]="deliveryRatesForm" (ngSubmit)="addRate()">
          <td>
            <select class="form-control" name="province" formControlName="province">
              <option *ngFor="let province of provinces" [ngValue]="province" class="form-control"
                >{{ province }}
              </option>
            </select>
          </td>
          <td>
            <select class="form-control" name="municipal" formControlName="municipality">
              <option *ngFor="let muni of municipalities" [ngValue]="muni">{{ muni }} </option>
            </select>
          </td>
          <td>
            <input class="form-control" name="rate" type="number" formControlName="rate" min="0" />
          </td>
          <td>
            <button class="btn ac-btn-primary" type="submit" [disabled]="deliveryRatesForm.invalid" (click)="addRate()">
              <i class="fas fa-plus-circle icon-btn"></i>
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rate of rates">
          <td>{{ rate.province }}</td>
          <td>{{ rate.municipality }}</td>
          <td>{{ rate.rate | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
          <td><i class="fas fa-trash-alt icon-btn" (click)="deleteRate(rate)"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
