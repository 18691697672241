import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportService } from '../report.service';
import { CommissionSummary } from '../../model/model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit, OnDestroy {
  private _commissions: CommissionSummary;
  private sub: Subscription;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this.sub = this.reportService.commissions.subscribe(r => {
      this._commissions = r;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get commissions() {
    return this._commissions;
  }

  get chartColors() {
    return this.reportService.colors;
  }

  private getChartData() {
    return [
      {
        name: 'Paid',
        value: this._commissions.paid
      },
      {
        name: 'Due',
        value: this._commissions.due
      }
    ];
  }

  get chartData(): number[] {
    return this.getChartData().map(d => d.value);
  }

  get chartLabels(): string[] {
    return this.getChartData().map(d => d.name);
  }

  get ready() {
    return !!this._commissions;
  }
}
