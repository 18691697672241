<div *ngIf="location">
  <div class="row mb-5">
    <div class="col-md-6 col-12 mb-5">
      <agm-map [latitude]="latitude" [longitude]="longitude" [minZoom]="4">
        <agm-marker
          [latitude]="latitude"
          [longitude]="longitude"
          [markerDraggable]="true"
          (dragEnd)="onMarkerDragEnd($event)"
        >
          <agm-info-window>{{ locationName.value }}</agm-info-window>
        </agm-marker>
      </agm-map>
    </div>
    <form class="col-md-6 col-12" [formGroup]="locationForm" (ngSubmit)="save()">
      <ngb-alert *ngIf="showNotification" type="warning" [dismissible]="false">
        <strong>Please Note</strong> <br />
        {{ notification.PRODUCT_LOCATIONS_NOT_UPDATED }}
      </ngb-alert>
      <div class="form-group row">
        <div class="col-4">
          <label>Location Name:</label>
        </div>
        <div class="col-8">
          <input class="form-control" type="text" name="locationName" formControlName="locationName" required />
          <div *ngIf="locationName.invalid && (locationName.dirty || locationName.touched)" class="mt-2">
            <mat-error *ngIf="locationName.hasError('required')">
              {{ error.REQUIRED_LOCATION_NAME }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-4">
          <label>Address:</label>
        </div>
        <div class="col-8">
          <input class="form-control" type="text" name="address1" formControlName="addressLine1" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-4"></div>
        <div class="col-8">
          <input class="form-control" type="text" name="address2" formControlName="addressLine2" />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-4">
          <label>City / Town:</label>
        </div>
        <div class="col-8">
          <input class="form-control" type="text" name="city" formControlName="city" required />
          <div *ngIf="city.invalid && (city.dirty || city.touched)" class="mt-2">
            <mat-error *ngIf="city.hasError('required')">
              {{ error.REQUIRED_CITY }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-4">
          <label>Province:</label>
        </div>
        <div class="col-8">
          <select class="form-control" type="text" name="city" formControlName="province" required>
            <option *ngFor="let prov of provinces" [ngValue]="prov">{{ prov }}</option>
          </select>
          <div *ngIf="province.invalid && (province.dirty || province.touched)" class="mt-2">
            <mat-error *ngIf="province.hasError('required')">
              {{ error.REQUIRED_PROVINCE }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-4">
          <label>Description:</label>
        </div>
        <div class="col-8">
          <textarea
            class="form-control"
            type="text"
            name="description"
            rows="3"
            formControlName="description"
          ></textarea>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-4">
          <label>Operating Hours:</label>
        </div>
        <ng-template #tipContent
          >Use the format: <br />
          Mo - Fr: 08:00AM - 17:00PM <br />
          Sa: 08:00AM - 12:00PM
        </ng-template>
        <div class="col-8">
          <textarea
            class="form-control"
            type="text"
            name="hours"
            rows="3"
            formControlName="operatingHours"
            required
            [ngbTooltip]="tipContent"
            placeholder="Mo - Fr: 08:00 - 17:00&#10;Sa: 08:00 - 13:00"
          >
          </textarea>
          <div *ngIf="operatingHours.invalid && (operatingHours.dirty || operatingHours.touched)" class="mt-2">
            <mat-error *ngIf="operatingHours.hasError('required')">
              {{ error.REQUIRED_OPERATING_HOURS }}
            </mat-error>
            <mat-error *ngIf="operatingHours.hasError('operatingHours')">
              {{ error.INVALID_OPERATING_HOURS }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <ngb-alert
            [type]="alert?.type"
            *ngIf="alert?.showAlert"
            (close)="alert.showAlert = false"
            [dismissible]="true"
          >
            {{ alert.message }}</ngb-alert
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col"></div>
        <div class="col-lg-6 col-md-8 col-12">
          <div class="row">
            <div class="col-6">
              <button class="btn btn-block ac-btn-secondary" type="reset" (click)="cancel()">CANCEL</button>
            </div>
            <div class="col-6">
              <button class="btn btn-block ac-btn-primary" [disabled]="pristineOrInvalid" type="submit">SAVE</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
