export interface Commission {
  id: number;
  product_id: number;
  order_id: number;
  total_due: number;
  qty: number;
  status: string;
  timestamp: Date;
}

export class CommissionSummary {
  constructor(public paid: number, public due: number) {}
}

export interface AuthProfile {
  token: string;
  username: string;
  user_display_name: string;
  user_email: string;
  user_nicename: string;
  roles: string[];
  cognito_token: string;
  cognito_identity_id: string;
}

export interface AuthError {
  code: string;
  data: any;
  message: string;
}

export interface TokenValidationResponse {
  code: string;
  message: string;
  data: {
    status: number;
  };
}
