import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from './products/products.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductWizardComponent } from './product-wizard/product-wizard.component';

import { ProductWizardModule } from './product-wizard/product-wizard.module';
import { ProductActionsComponent } from './product-actions/product-actions.component';
import { NavigationModule } from '../navigation/navigation.module';
import { NgbPopoverModule, NgbTooltipModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule, ConfirmDialogComponent } from 'core';
import { NgxPaginationModule } from 'ngx-pagination';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
  declarations: [
    ProductsListComponent,
    ProductSummaryComponent,
    ProductsComponent,
    ProductWizardComponent,
    ProductActionsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    ProductWizardModule,
    NavigationModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbAlertModule,
    NgxPaginationModule,
    ProductsRoutingModule,
    ScrollToModule.forRoot()
  ],
  exports: [ProductsListComponent],
  entryComponents: [ConfirmDialogComponent]
})
export class ProductsModule {}
