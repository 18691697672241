import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, IProduct } from 'core';
import { UserService } from '../../user.service';
import { ProductsService } from '../products.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  selectedProduct: IProduct;
  alert: Alert;
  private subs: Subscription[];
  private _currentPage: number;
  private _pageLimit: number;
  private _innerWidth: number;

  constructor(
    private productService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private user: UserService,
    private scrollToService: ScrollToService
  ) {
    this.subs = [];
    this._pageLimit = 10;
  }

  ngOnInit() {
    const queryObs$ = this.route.queryParamMap;

    this._innerWidth = window.innerWidth;
    this.subs.push(this.productService.getProducts({ limit: '-1' }).subscribe());

    this.subs.push(this.user.notifications$.subscribe(alert => (this.alert = alert)));

    this.subs.push(
      queryObs$
        .pipe(
          filter(paramMap => {
            //set default params if none is set.
            const hasDefaultValues = paramMap.has('page');
            if (!hasDefaultValues) {
              this.router.navigate(['/products'], { queryParams: { ...paramMap['params'], page: '1' } });
              return false;
            }
            return true;
          })
        )
        .subscribe({
          next: paramMap => {
            this._currentPage = parseInt(paramMap.get('page'), 10);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) sub.unsubscribe();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._innerWidth = event.target.innerWidth;
  }
  get products() {
    return this.productService.productsData;
  }

  get productCount() {
    return this.products ? this.products.length : 0;
  }

  get pageLimit() {
    return this._pageLimit;
  }

  get currentPage() {
    return this._currentPage;
  }

  add() {
    if (this.user.is_pending) {
      this.user.pendingAccountNotification('Cannot add new products');
      return;
    }
    this.router.navigate(['edit', 'new'], { relativeTo: this.route });
  }

  changePage(pageNumber: number) {
    this.scrollToService.scrollTo({ target: 'topPaginateController' });
    this.router.navigate(['/products'], { queryParams: { page: pageNumber } });
  }

  get showDirectionLinks() {
    return this._innerWidth > 576;
  }

  get numberOfPageLinks() {
    return this._innerWidth < 360 ? 5 : 7;
  }
}
