import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../../user.service';
import { Address, BankAccount, MerchantProfile } from 'core';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  @Output() isActive = new EventEmitter<boolean>();

  billingForm = this.fb.group({
    address: this.fb.group({
      address_1: [''],
      address_2: [''],
      city: [''],
      postal_code: ['']
    }),
    tel: [''],
    vat_no: ['']
  });

  adminEmail = 'elmarie@agrichecker.com';
  bankingDetails: BankAccount;

  constructor(private userService: UserService, private fb: FormBuilder) {}

  ngOnInit() {
    if (this.userService.profile) {
      this.userService.profile.subscribe(prof => {
        this.setFormValue(prof);
        this.bankingDetails = prof.bank_account;
        this.billingForm.markAsPristine();
      });
    }
  }

  private setFormValue(profile: MerchantProfile) {
    this.billingForm.patchValue(profile);
  }

  cancel() {
    //reload data from API
    this.userService.fetchProfile();
  }

  save() {
    if (this.billingForm.dirty && this.billingForm.valid) {
      const addressForm: FormGroup = this.billingForm.get('address') as FormGroup;
      const address = {
        address_1: addressForm.get('address_1').value,
        address_2: addressForm.get('address_2').value,
        city: addressForm.get('city').value,
        postal_code: addressForm.get('postal_code').value
      } as Address;
      const tel = this.billingForm.get('tel').value,
        vat = this.billingForm.get('vat_no').value;

      this.userService.updateBillingInfo(address, tel, vat);
    }
  }
}
