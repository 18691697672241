import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { OrderAnalytics, ReportService } from '../report.service';

@Component({
  selector: 'app-top-products',
  templateUrl: './top-products.component.html',
  styleUrls: ['./top-products.component.scss']
})
export class TopProductsComponent implements OnInit, OnDestroy {
  private _topProducts$: Observable<any>;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this._topProducts$ = this.reportService.orders_analytics$.pipe(
      filter(res => res && res.top_products),
      map((res: OrderAnalytics) => {
        return Object.keys(res.top_products).map(key => {
          return {
            id: key,
            name: res.top_products[key].name,
            qty: res.top_products[key].qty
          };
        });
      })
    );
  }

  ngOnDestroy() {}

  get topProducts(): Observable<any> {
    return this._topProducts$ ? this._topProducts$ : of({});
  }
}
