import { Injectable } from '@angular/core';
import { ApiService, LoginResult, Namespace, Roles, Endpoints } from 'core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthError, AuthProfile, TokenValidationResponse } from '../model/model';

class AppStorage<T> {
  constructor(private key: string) {}

  get exists() {
    return !!sessionStorage.getItem(this.key);
  }

  get value(): T {
    return <T>JSON.parse(sessionStorage.getItem(this.key));
  }

  put(value: T) {
    sessionStorage.setItem(this.key, JSON.stringify(value));
  }

  remove() {
    sessionStorage.removeItem(this.key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static readonly storageKey = 'jwt-token';
  private auth_token: string;
  private tokenStorage = new AppStorage<AuthProfile>(AuthService.storageKey);
  private loggedIn$: Subject<boolean>;
  redirectUrl: string;

  constructor(private api: ApiService) {
    this.loggedIn$ = new ReplaySubject();
    if (this.tokenStorage.exists) {
      this.auth_token = this.tokenStorage.value.token;
      this.loggedIn$.next(!!this.auth_token);
    }
  }

  private isAuthSuccess(data: AuthProfile | AuthError): data is AuthProfile {
    return (<AuthProfile>data).token !== undefined;
  }

  private storeToken(data) {
    if (this.isAuthSuccess(data)) {
      this.auth_token = data.token;
      this.tokenStorage.put(data);
      this.loggedIn$.next(!!this.auth_token);
    }
  }

  private validateToken(token: string) {
    this.auth_token = token;

    const obs = this.api.post(Namespace.jwt, '/token/validate').pipe(
      map((response: TokenValidationResponse) => response.data.status === 200),
      tap((response: boolean) => {
        if (response) this.storeToken(this.auth_token);
      })
    );

    obs.subscribe(
      res => this.loggedIn$.next(res),
      err => {
        console.log(err);
        this.auth_token = undefined;
        this.loggedIn$.next(false);
      }
    );
    return obs;
  }

  get authStatus(): Observable<boolean> {
    return this.loggedIn$;
  }

  get cognito_token() {
    return this.tokenStorage.value.cognito_token;
  }

  get cognito_identity_id() {
    return this.tokenStorage.value.cognito_identity_id;
  }

  login(username: string, password: string): Observable<LoginResult> {
    // map observable to return more sensible data...
    return this.api
      .post(Namespace.jwt, Endpoints.signin, {
        username: username,
        password: password
      })
      .pipe(
        map((data: AuthProfile) => {
          if (this.isAuthSuccess(data) && data.roles.includes(Roles.customer)) {
            return {
              authorised: false,
              is_customer: true
            } as LoginResult;
          } else if (this.isAuthSuccess(data)) {
            if (data.roles.includes(Roles.merchant)) {
              this.storeToken(<AuthProfile>data);
              return {
                authorised: true
              };
            } else if (data.roles.includes(Roles.pending_merchant)) {
              return {
                authorised: false,
                is_pending: true
              };
            }
          }
          return {
            authorised: false
          };
        })
      );
  }

  loginWithToken(token: string): Observable<boolean> {
    return this.validateToken(token);
  }

  logout(): Observable<boolean> {
    // create event on loggedIn observable
    this.tokenStorage.remove();
    this.auth_token = undefined;
    this.loggedIn$.next(!!this.auth_token);

    return of(true);
  }

  get authToken() {
    return this.auth_token;
  }

  get isLoggedIn() {
    return !!this.auth_token;
  }
}
