import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Link, ImageSize } from 'core';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  breadcrumb: Link;
  show = false;

  readonly SHOP_URL = environment.shop_url;

  constructor(private user: UserService, private auth: AuthService, private router: Router) {
    this.breadcrumb = {
      title: 'Dashboard',
      url: '/'
    };
  }

  ngOnInit() {}

  get company() {
    return this.user.profileData ? this.user.company : '';
  }

  get logo(): string {
    if (!this.auth.isLoggedIn) return 'assets/img/AC-ICON-300x300.png';
    return this.user.getLogo(ImageSize.thumbnail);
  }

  private logout() {
    return this.auth.logout().subscribe(_ => this.router.navigate(['/signin']));
  }

  get authStatus() {
    return this.auth.isLoggedIn ? 'Sign Out' : '';
  }

  signout() {
    if (this.auth.isLoggedIn) {
      this.logout();
    }
  }
}
