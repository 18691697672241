import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  private _dirty$: Subject<boolean> = new Subject();
  constructor() {}

  markDirty() {
    this._dirty$.next(true);
  }

  markPristine() {
    this._dirty$.next(false);
  }

  get dirty(): Observable<boolean> {
    return this._dirty$;
  }
}
