import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { LocationsListComponent } from './locations-list/locations-list.component';
import { LocationsComponent } from './locations/locations.component';

const locationRoutes: Routes = [
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: LocationsListComponent,
        children: [{ path: ':id', component: LocationDetailComponent }]
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(locationRoutes)],
  exports: [RouterModule]
})
export class LocationRoutingModule {}
