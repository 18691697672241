<h4>Top Selling Products</h4>
<table class="table">
  <thead>
    <th>Name</th>
    <th>Qty</th>
  </thead>
  <tbody>
    <tr *ngFor="let product of topProducts | async">
      <td>
        <a [routerLink]="['/products/edit', product?.id]">{{ product?.name }}</a>
      </td>
      <td>{{ product?.qty }}</td>
    </tr>
  </tbody>
</table>
