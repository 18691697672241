<div class="row">
  <div class="col justify-content-center action-buttons">
    <div class="mb-2">
      <i class="fas fa-pencil-alt icon-btn" placement="top" ngbTooltip="Edit" (click)="edit()"></i>
    </div>
    <div class="mb-2">
      <i class="fas fa-trash-alt icon-btn" placement="top" ngbTooltip="Delete" (click)="delete()"></i>
    </div>
    <div *ngIf="draft; else published">
      <div class="mb-2">
        <i class="fas fa-upload icon-btn" placement="top" ngbTooltip="Publish" (click)="publish()"></i>
      </div>
    </div>
    <ng-template #published>
      <div class="mb-2">
        <i class="fas fa-eye-slash icon-btn" placement="top" ngbTooltip="Unpublish" (click)="unpublish()"></i>
      </div>
    </ng-template>

    <div *ngIf="isShopProduct; else classified">
      <div>
        <i
          class="fas fa-newspaper icon-btn"
          placement="top"
          ngbTooltip="Add to Classifieds"
          (click)="addToClassifieds()"
        ></i>
      </div>
    </div>
    <ng-template #classified>
      <div>
        <i class="fas fa-shopping-cart icon-btn" placement="top" ngbTooltip="Add to Shop" (click)="addToShop()"></i>
      </div>
    </ng-template>
  </div>
</div>
