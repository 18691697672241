import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CityLookupService, DeliveryRate, DeliveryRates } from 'core';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-delivery-rates',
  templateUrl: './delivery-rates.component.html',
  styleUrls: ['./delivery-rates.component.scss']
})
export class DeliveryRatesComponent implements OnInit {
  private _municipalities: string[];
  private province_options: Array<string>;

  private _is_quote;
  private _rates: DeliveryRate[];
  @Output() ratesChange = new EventEmitter<DeliveryRate[]>();

  deliveryRatesForm = this.fb.group({
    province: ['', Validators.required],
    municipality: ['', Validators.required],
    rate: ['', [Validators.required, Validators.min(0)]]
  });

  constructor(private fb: FormBuilder, private cities: CityLookupService) {
    this.province_options = new Array();
    this.province_options.push('All');
    this.province_options.push(...CityLookupService.PROVINCES);
  }

  ngOnInit() {
    this.deliveryRatesForm
      .get('province')
      .valueChanges.pipe(
        switchMap(prov => {
          this.deliveryRatesForm.get('municipality').setValue('All');
          if (prov === 'All') {
            return of(['All']);
          } else {
            return this.cities.getMunicipalitiesInProvince(prov);
          }
        })
      )
      .subscribe((munis: Array<string>) => {
        if (munis.length > 1) {
          this._municipalities = new Array();
          this._municipalities.push('All');
          this._municipalities.push(...munis);
        } else {
          this._municipalities = munis;
        }
      });

    this.deliveryRatesForm.valueChanges.pipe(filter(_ => this.deliveryRatesForm.dirty)).subscribe();
  }

  addRate() {
    const prov = this.deliveryRatesForm.get('province').value;
    const rate = this.deliveryRatesForm.get('rate').value;
    const muni = this.deliveryRatesForm.get('municipality').value;

    const drate: DeliveryRate = {
      province: prov,
      municipality: muni,
      rate: rate
    };

    const delivery_rates = new DeliveryRates(this.rates);
    delivery_rates.addRate(drate);
    this.rates = delivery_rates.rates;
    this.ratesChange.emit(delivery_rates.rates);
  }

  deleteRate(rate: DeliveryRate) {
    const delivery_rates = new DeliveryRates(this.rates);
    delivery_rates.deleteRate(rate);
    this.rates = delivery_rates.rates;
    this.ratesChange.emit(delivery_rates.rates);
  }

  get provinces() {
    return this.province_options;
  }

  get municipalities() {
    return this._municipalities;
  }

  @Input()
  set rates(rates: DeliveryRate[]) {
    this._rates = rates;
  }

  get rates() {
    return this._rates;
  }

  @Input()
  set is_quote(val: boolean) {
    this._is_quote = val;
    if (this._is_quote) {
      this.deliveryRatesForm.get('rate').disable();
    }
  }

  get is_quote() {
    return this._is_quote;
  }
}
