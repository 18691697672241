import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDetails } from 'core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerInfoComponent } from '../customer-info/customer-info.component';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  private _selected_order: OrderDetails;

  @Input()
  orders: OrderDetails[];

  constructor(private router: Router, private modalService: NgbModal, private customer: UserService) {}

  ngOnInit() {}

  public selectOrder(order): void {
    this._selected_order = order;
    this.router.navigate(['/orders/', order.id]);
  }

  get selected_order() {
    return this._selected_order;
  }

  open(order: OrderDetails) {
    this.customer.getCustomerById(order.customer.id).subscribe(customer => {
      const modalRef = this.modalService.open(CustomerInfoComponent);
      modalRef.componentInstance.customer = customer;
    });
  }
}
