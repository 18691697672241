<div class="product-card">
  <div class="item">
    <div *ngIf="pending || draft">
      <span class="status-badge" [ngClass]="{ 'draft-badge': draft }">{{ status }}</span>
    </div>
  </div>

  <div class="card" [ngClass]="{ 'pending-product': pending, 'draft-product': draft, 'publish-product': published }">
    <div class="row my-2 mx-1">
      <div class="col-md-2 hidden-xs d-flex align-items-center justify-content-center d-none d-md-block">
        <div *ngIf="product_image; else default">
          <img class="img-thumbnail ml-2 d-none d-md-block" [src]="product_image" alt="Logo" />
        </div>
        <ng-template #default
          ><i class="fas fa-box-open fa-5x ac-product-logo block d-none d-md-block"></i>
        </ng-template>
      </div>
      <div class="col-lg-7 col-5">
        <div class="row">
          <div class="col-md-9 col-12">
            <h5>{{ product?.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-12">
            <div class="ac-merchant-link">{{ merchant }}</div>
            <div class="row">
              <div class="col">
                <acx-product-attribute [attribute]="attribute1"></acx-product-attribute>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <acx-product-attribute [attribute]="attribute2"></acx-product-attribute>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-12">
            <div *ngIf="collection" class="row">
              <div class="col">
                Collect at <strong>{{ collection }}</strong>
              </div>
            </div>
            <div *ngIf="delivery" class="row">
              <div class="col">
                Delivered in <strong>{{ delivery }}</strong> days
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="price-box col-lg-3 col-5">
        <div class="row">
          <div *ngIf="product?.price.isPresent; else quote">
            <div class="col">
              <label class="font-weight-bold">Price:</label>
              <span class="ac-price-label">
                {{ price | currency: 'ZAR':'symbol-narrow':'1.2-2' }}
                {{ price_unit }}
              </span>
            </div>
          </div>
          <ng-template #quote>
            <div class="col">
              <label class="font-weight-bold">Price:</label>
              <span class="ac-price-label"> {{ price }} {{ price_unit }} </span>
            </div>
          </ng-template>
        </div>
        <div>
          <div *ngIf="isShop">
            <label><strong>Stock:</strong> </label
            ><span class="stock-badge" [ngClass]="{ 'out-of-stock-badge': out_of_stock }"> {{ stock }}</span>
          </div>
          <div *ngIf="product?.batch_size" class="row">
            <div class="col"><label class="font-weight-bold">Batch Size: </label> {{ product?.batch_size }}</div>
          </div>
          <div class="row">
            <div class="col-auto"><label class="font-weight-bold">Min Qty: </label> {{ product?.order_size?.min }}</div>
            <div class="col-auto"><label class="font-weight-bold">Max Qty: </label> {{ product?.order_size?.max }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn ac-btn-buy btn-block bg-primary">
              <i class="fas mr-2 hidden-sm-down" [ngClass]="button.icon"></i>{{ button.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
