<div class="main-container">
  <app-header></app-header>
  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <acx-footer [links]="footer_links"></acx-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="default" color="#fff" type="cube-transition" [fullScreen]="true">
  <p class="load-text">Loading...</p>
</ngx-spinner>
