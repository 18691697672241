import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../locations/location.service';
import { Alert, AlertType } from 'core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  alert: Alert;
  constructor(private locationService: LocationService) {}

  ngOnInit() {
    this.locationService.locations.subscribe(locations => {
      if (locations.length === 0) {
        this.alert = new Alert(AlertType.warning, "You haven't added any collection locations.");
      }
    });
  }
}
