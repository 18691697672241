import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IProduct, ProductStatus, ProductType, ConfirmDialogComponent, NOTIFICATION } from 'core';
import { from, Subscription } from 'rxjs';
import { filter, switchMap, first } from 'rxjs/operators';
import { ConfirmDeleteComponent } from '../../confirm-delete/confirm-delete.component';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-product-actions',
  templateUrl: './product-actions.component.html',
  styleUrls: ['./product-actions.component.scss']
})
export class ProductActionsComponent implements OnInit, OnDestroy {
  @Input() product: IProduct;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductsService,
    private modal: NgbModal
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  edit() {
    this.router.navigate(['edit', this.product.id], { relativeTo: this.route });
  }

  delete() {
    // add modal
    const modalRef = this.modal.open(ConfirmDeleteComponent);
    modalRef.componentInstance.entity = this.product.name;
    from(modalRef.result)
      .pipe(
        filter(res => res),
        switchMap(_ => this.productService.delete(this.product.id.toString())),
        switchMap(_ => this.productService.getProducts()),
        first()
      )
      .subscribe();
  }

  addToClassifieds() {
    this.productService
      .addToClassifieds(this.product.id.toString())
      .pipe(
        switchMap(_ => this.productService.getProducts()),
        first()
      )
      .subscribe();
  }

  addToShop() {
    const wasQuote = this.product.product_type === ProductType.quote;
    this.productService
      .addToShop(this.product.id.toString())
      .pipe(
        switchMap(_ => this.productService.getProducts()),
        first()
      )
      .subscribe(_ => {
        if (wasQuote) {
          this.router.navigate(['edit', this.product.id, 'pricing'], { relativeTo: this.route });
        }
      });
  }

  get isShopProduct() {
    return this.product.product_type === ProductType.shop;
  }

  get pending() {
    return this.product.status === ProductStatus.pending;
  }

  get draft() {
    return this.product.status === ProductStatus.draft;
  }

  publish() {
    this.productService
      .publish(this.product.id.toString())
      .pipe(
        switchMap(_ => this.productService.getProducts()),
        first()
      )
      .subscribe();
  }

  unpublish() {
    const ref = this.modal.open(ConfirmDialogComponent);
    ref.componentInstance.title = 'Unpublish Product';
    ref.componentInstance.message = NOTIFICATION.PRODUCT_CONFIRM_UNPUBLISH;
    ref.componentInstance.continueText = 'Unpublish';

    ref.result.then(
      res =>
        res &&
        this.productService
          .unPublish(this.product.id.toString())
          .pipe(
            switchMap(_ => this.productService.getProducts()),
            first()
          )
          .subscribe()
    );
  }
}
