import { Component, Input, OnInit } from '@angular/core';
import { BillingProfile, CollectionLocation, DeliveryAddressProfile, OrderDetails } from 'core';
import { LocationService } from '../../locations/location.service';

@Component({
  selector: 'app-order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss']
})
export class OrderAddressComponent implements OnInit {
  private _collection_info: CollectionLocation;
  private _order: OrderDetails;

  constructor(private location: LocationService) {}

  ngOnInit() {}

  @Input()
  set order(val: OrderDetails) {
    if (val) {
      this._order = val;
      if (this._order.collection_location) {
        this.location
          .getCollectionLocationById(this._order.collection_location.id)
          .subscribe(loc => (this._collection_info = loc));
      }
    }
  }

  get order() {
    return this._order;
  }

  get billing(): BillingProfile {
    return this.order ? this.order.billing_info : <BillingProfile>{};
  }

  get longitude(): number {
    if (this.collection && this._collection_info) {
      return this.collection_info.geo_location.longitude;
    }

    return this.shipping ? this.shipping.geo_location.longitude : 25;
  }

  get latitude(): number {
    if (this.collection && this.collection_info) {
      return this.collection_info.geo_location.latitude;
    }
    return this.shipping ? this.shipping.geo_location.latitude : -25;
  }

  get shipping(): DeliveryAddressProfile {
    return this.order ? this.order.delivery_info : null;
  }

  get collection(): boolean {
    return this.order ? !!this.order.collection_location : false;
  }

  get collection_info() {
    return this._collection_info;
  }
}
