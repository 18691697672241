<div class="modal-header">
  <h4 class="modal-title">Confirm Delete</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form>
  <div class="modal-body">
    <p>
      Are you sure you want to delete <strong>{{ entity }}</strong
      >?
    </p>

    <label>Please type <strong>DELETE</strong> to confirm</label>
    <input class="form-control" type="text" #input (keyup)="(0)" appAutofocus />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-danger" (click)="delete()" [disabled]="input.value.toUpperCase() !== 'DELETE'">
      DELETE
    </button>
  </div>
</form>
