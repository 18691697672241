<div class="row justify-content-end pr-4">
  <div (click)="add()">
    <label class="ac-click-label"><i class="fas fa-plus-circle fa-2x btn ac-icon-btn"></i>Add Product</label>
  </div>
</div>
<ngb-alert *ngIf="alert?.showAlert" (close)="alert.showAlert = false" [dismissible]="true">{{
  alert.message
}}</ngb-alert>
<div>
  <div class="mb-5">
    <div
      id="topPaginateController"
      class="d-flex flex-row justify-content-center justify-content-sm-end pr-sm-0 pt-3"
      style="padding-right: 40px"
    >
      <pagination-controls
        [directionLinks]="showDirectionLinks"
        [maxSize]="numberOfPageLinks"
        *ngIf="productCount > pageLimit"
        (pageChange)="changePage($event)"
      ></pagination-controls>
    </div>
    <div
      *ngFor="
        let product of products
          | paginate
            : {
                itemsPerPage: pageLimit,
                currentPage: currentPage,
                totalItems: productCount
              }
      "
    >
      <div>
        <ng-template #popContent>
          <div class="sm-block">
            <app-product-actions [product]="product"></app-product-actions>
          </div>
        </ng-template>
        <div
          [ngbPopover]="popContent"
          class="col-12 action-menu"
          placement="right"
          [autoClose]="'outside'"
          #popOver="ngbPopover"
        >
          <app-product-summary [product]="product"></app-product-summary>
        </div>
      </div>
    </div>
    <div
      id="bottomPaginateController"
      class="d-flex flex-row justify-content-center justify-content-sm-end pr-sm-0 pt-3"
      style="padding-right: 40px"
    >
      <pagination-controls
        *ngIf="productCount > pageLimit"
        [directionLinks]="showDirectionLinks"
        [maxSize]="numberOfPageLinks"
        (pageChange)="changePage($event)"
      ></pagination-controls>
    </div>
  </div>
</div>
