<app-nav></app-nav>

<div>
  <div class="row">
    <div class="col col-md"></div>
    <div class="col-md-6 col-lg-4">
      <div class="float-right">
        <label>
          <h5>Account Status:</h5>
        </label>
        <span class="ml-2">{{ status }} <i [class]="statusIconClass"></i></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mb-5" id="profile">
        <h4>Profile:</h4>
        <app-profile></app-profile>
      </div>

      <div class="mb-5" id="billing">
        <h4>Billing:</h4>
        <app-billing></app-billing>
      </div>

      <div class="mb-5" id="contacts">
        <h4>Contacts:</h4>
        <app-contacts></app-contacts>
      </div>
    </div>
  </div>
</div>
