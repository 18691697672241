<div class="row">
  <div class="col-md-6 col-12">
    <form [formGroup]="productPriceForm" (ngSubmit)="next()">
      <div *ngIf="!is_quote">
        <div class="form-group">
          <label>Unit Price (R) Excl. VAT:</label>
          <input class="form-control" type="number" formControlName="price" />
          <div *ngIf="price.invalid" class="mt-2">
            <mat-error *ngIf="price.errors?.required || price.errors?.min">
              {{ ErrorStrings.NON_ZERO_PRICE_REQUIRED }}
            </mat-error>
          </div>
        </div>
        <div class="form-group">
          <label>VAT Rate:</label>
          <select class="form-control" formControlName="vat_rate">
            <option *ngFor="let rate of vat_rates" [ngValue]="rate">{{ rate }}</option>
          </select>
          <div *ngIf="vat_rate.invalid" class="mt-2">
            <mat-error *ngIf="vat_rate.errors?.required">
              {{ ErrorStrings.REQUIRED_VAT_RATE }}
            </mat-error>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <label>Unit Size:</label>
            <input class="form-control" type="number" formControlName="unit_size" />
            <div *ngIf="productPriceForm.controls.unit_size.invalid" class="mt-2">
              <mat-error *ngIf="productPriceForm.controls.unit_size.errors?.not_a_number">
                {{ ErrorStrings.INVALID_NUMBER }}
              </mat-error>
              <mat-error *ngIf="productPriceForm.controls.unit_size.errors?.min">
                {{ ErrorStrings.POSITIVE_NUMBER_REQUIRED }}
              </mat-error>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-8">
            <label>Measurement Unit:</label>
            <input class="form-control" type="text" formControlName="unit" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-8 col-8">
            <label>Batch Size:</label>
            <input class="form-control" type="number" formControlName="batch_size" [min]="1" />
            <div *ngIf="productPriceForm.controls.batch_size.invalid" class="mt-2">
              <mat-error *ngIf="productPriceForm.controls.batch_size.errors?.min">
                {{ ErrorStrings.MINIMUM_BATCH_SIZE }}
              </mat-error>
              <mat-error *ngIf="productPriceForm.controls.batch_size.errors?.not_whole_number">
                {{ ErrorStrings.WHOLE_NUMBER_REQUIRED }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <h5>Order Size</h5>
        <div class="row">
          <div class="col">
            <label>Min:</label>
            <input
              class="form-control"
              type="number"
              formControlName="order_size_min"
              [min]="batch_size || 1"
              [step]="batch_size || 1"
            />
            <div *ngIf="productPriceForm.controls.order_size_min.invalid" class="mt-2">
              <mat-error *ngIf="productPriceForm.controls.order_size_min.errors?.min">
                {{ ErrorStrings.MINIMUM_BATCH_SIZE }}
              </mat-error>
              <mat-error *ngIf="productPriceForm.controls.order_size_min.errors?.not_whole_number">
                {{ ErrorStrings.WHOLE_NUMBER_REQUIRED }}
              </mat-error>
            </div>
          </div>
          <div class="col">
            <label>Max:</label>
            <input
              class="form-control"
              type="number"
              formControlName="order_size_max"
              [min]="batch_size"
              [step]="batch_size"
            />
            <div *ngIf="productPriceForm.controls.order_size_max.invalid" class="mt-2">
              <mat-error *ngIf="productPriceForm.controls.order_size_max.errors?.not_whole_number">
                {{ ErrorStrings.WHOLE_NUMBER_REQUIRED }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf="productPriceForm.errors?.order_size" class="mt-2">
          <mat-error *ngIf="productPriceForm.errors?.order_size">
            {{ ErrorStrings.MIN_MAX_ORDER_SIZE }}
          </mat-error>
        </div>
      </div>
      <div class="form-group row my-5">
        <div class="col-lg col-0"></div>
        <div class="col-lg-3 col-4">
          <button class="btn btn-block ac-btn-secondary" type="button" (click)="back()">Back</button>
        </div>
        <div class="col-lg-3 col-4">
          <button class="btn btn-block ac-btn-secondary" type="button" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-lg-3 col-4">
          <button class="btn btn-block ac-btn-primary" type="submit" [disabled]="productPriceForm.invalid">
            {{ button_text }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
