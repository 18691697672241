import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetails, DeliveryMethod, OrderStatus, OrderUpdateRequest } from 'core';
import { formatDate } from '@angular/common';

const toString = (dateStruct: NgbDateStruct): string => {
  return formatDate(
    String(dateStruct.year) + '-' + String(dateStruct.month) + '-' + dateStruct.day,
    'y-MM-dd',
    'en-US'
  );
};

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {
  @Input()
  set order(order: OrderDetails) {
    this._order = order;
    this.init();
  }

  @Output() orderUpdateRequest = new EventEmitter<OrderUpdateRequest>();
  @ViewChild('dp') datepicker: NgbDatepicker;

  selected_status: OrderStatus;
  edit_status = false;
  edit_date = false;
  new_delivery_date: NgbDateStruct;
  date: { year: number; month: number };

  private _order: OrderDetails;
  private _min_date: NgbDateStruct;
  private _max_date: NgbDateStruct;

  constructor() {}

  private getMinDate(): NgbDateStruct {
    const d = this._order && this._order.date_created ? new Date(this._order.date_created) : new Date();
    return this.dateToNgbDateStructAdapter(d);
  }

  private getMaxDate(): NgbDateStruct {
    const d = this.dateToNgbDateStructAdapter(new Date());
    d.month += 2;
    return d;
  }

  private dateToNgbDateStructAdapter(d: Date): NgbDateStruct {
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  ngOnInit() {}

  private init() {
    this._min_date = this.getMinDate();
    this._max_date = this.getMaxDate();
    this.new_delivery_date = this.dateToNgbDateStructAdapter(new Date(this.delivery_date));
    this.selected_status = this._order ? this._order.status : (this.status_list[0] as OrderStatus);
  }

  setDatePicker() {
    this.edit_date = !this.edit_date;
    if (this.edit_date) {
      setTimeout(_ => this.datepicker.navigateTo(this.new_delivery_date), 1); //if this happens immediately, the viewchild is still undefined.
    }
  }

  setEditStatus(status: boolean) {
    this.edit_status = status;
  }

  save() {
    const req = {
      id: this._order.id,
      status: this.selected_status,
      delivery_date: this.for_delivery ? toString(this.new_delivery_date) : null
    } as OrderUpdateRequest;
    this.orderUpdateRequest.emit(req);
    this.setEditStatus(false);
  }

  get is_dirty(): boolean {
    return this.is_status_dirty || this.is_date_dirty;
  }

  get is_status_dirty(): boolean {
    return this.selected_status !== this.status;
  }

  get is_date_dirty(): boolean {
    const d = this.dateToNgbDateStructAdapter(new Date(this.delivery_date));
    return JSON.stringify(d) !== JSON.stringify(this.new_delivery_date);
  }

  get status(): string {
    return this._order ? this._order.status : undefined;
  }

  get for_delivery(): boolean {
    return this._order && this._order.delivery_method === DeliveryMethod.Delivery;
  }

  get delivery_date(): string {
    if (this.for_delivery) {
      return (
        (this._order && this._order.delivery_info.delivery_date) ||
        (this._order && this._order.date_created) ||
        new Date().toDateString()
      );
    }
    return '';
  }

  get status_list() {
    const _status_list = Object.values(OrderStatus).slice(this.selected_status === OrderStatus.pending ? 0 : 1);
    if (this.for_delivery) {
      _status_list.splice(_status_list.indexOf(OrderStatus.collect), 1);
    }
    return _status_list;
  }

  get min_date(): NgbDateStruct {
    return this._min_date;
  }

  get max_date(): NgbDateStruct {
    return this._max_date;
  }
}
