import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationModule } from '../navigation/navigation.module';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { LocationRoutingModule } from './location-routing.module';
import { LocationsListComponent } from './locations-list/locations-list.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [LocationsListComponent, LocationDetailComponent, LocationsComponent, LocationDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-1Ii54pEm-3N84LeNYnqWtoRd0THpOPw',
      libraries: ['places']
    }),
    NgbTooltipModule,
    NgbAlertModule,
    NavigationModule,
    LocationRoutingModule,
    MatFormFieldModule
  ],
  entryComponents: [LocationDialogComponent]
})
export class LocationsModule {}
