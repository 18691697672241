import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ERROR, ImageSize, MerchantProfile, S3Uploader } from 'core';
import { environment } from '../../../environments/environment';
import { FileContent } from '../../file-upload/file-upload.component';
import { UploaderService } from '../../uploader.service';
import { UserService } from '../../user.service';

const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const updatePassword = control.get('updatePassword');

  const oldPassword = control.get('oldPassword');
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  if (updatePassword.value) {
    return oldPassword.value && newPassword.value && confirmPassword.value === newPassword.value
      ? null
      : { passwordMatch: true };
  }
  return null;
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  _logo: string;
  errors = ERROR;
  loading = false;

  accountForm = this.fb.group(
    {
      companyName: ['', Validators.required],
      website: [''],
      tsAndCs: [''],
      email: [''],
      updatePassword: [false],
      oldPassword: [''],
      newPassword: ['', [Validators.minLength(6)]],
      confirmPassword: ['', [Validators.minLength(6)]]
    },
    {
      validator: passwordMatchValidator // password length, password strength, password required
    }
  );

  adminEmail = 'elmarie@agrichecker.com';
  showToolTip: boolean;

  private s3: S3Uploader;

  constructor(private fb: FormBuilder, private userService: UserService, private upload: UploaderService) {
    this.s3 = upload.getS3Uploader(environment.cdn.bucket);
  }

  ngOnInit() {
    this.userService.profile.subscribe(prof => {
      this.setFormValue(prof);
      this._logo = prof.logo;
      this.accountForm.markAsPristine();
    });
  }

  private setFormValue(profile: MerchantProfile) {
    this.accountForm.patchValue({
      companyName: profile.company,
      website: profile.website,
      tsAndCs: profile.terms_and_conditions,
      email: profile.email,
      updatePassword: false,
      oldPassword: '',
      newPassword: ''
    });
  }

  get oldPassword() {
    return this.accountForm.get('oldPassword');
  }

  get newPassword() {
    return this.accountForm.get('newPassword');
  }

  get confirmPassword() {
    return this.accountForm.get('confirmPassword');
  }

  private get name() {
    return this.accountForm.get('companyName').value;
  }

  private get website() {
    return this.accountForm.get('website').value;
  }

  get updatePassword() {
    return this.accountForm.get('updatePassword').value;
  }

  get logo() {
    return this.userService.getLogo(ImageSize.medium);
  }

  private get user_key() {
    return this.userService.profileData ? `users/${this.userService.profileData.id}` : '';
  }

  handleImage(event: FileContent) {
    this.loading = true;
    this.s3.upload(event.file, event.content, this.user_key).subscribe(key => {
      this.loading = false;
      if (this.userService.updateLogo(key)) {
        this.accountForm.markAsDirty();
        this._logo = typeof key === 'string' ? key : '';
      }
    });
  }

  cancel() {
    //reload data from API
    this.userService.fetchProfile();
  }

  save() {
    if (this.accountForm.dirty && this.accountForm.valid) {
      if (this.accountForm.get('updatePassword').value) {
        this.userService.updatePassword(this.oldPassword.value, this.newPassword.value);
      }
      this.userService.updateProfile(this.name, this.website);
    }
  }
}
