import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  continue() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.close(false);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
