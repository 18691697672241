import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert, AlertType, LoginResult, ERROR } from 'core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
  loginError = false;
  username: string;
  password: string;
  alert: Alert;
  is_customer = false;
  readonly errors = ERROR;

  readonly SHOP_URL = environment.shop_url;

  links = {
    forgot_password: this.SHOP_URL + '/signin?forgot_password',
    register: this.SHOP_URL + '/register/merchant'
  };

  private sub: Subscription;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  login(username: string, password: string) {
    this.sub = this.auth.login(username, password).subscribe(
      (res: LoginResult) => {
        if (!res.authorised) {
          if (res.is_customer) {
            this.alert = new Alert(
              AlertType.danger,
              'Only merchants can use the Merchant Dashboard. Customers should sign in to Agrichecker'
            );
            this.is_customer = !!res.is_customer;
          } else if (res.is_pending) {
            this.alert = new Alert(AlertType.danger, 'Your merchant account has not been confirmed yet');
          } else {
            this.alert = new Alert(AlertType.danger, this.errors.LOGIN_ERROR);
          }
        } else {
          if (this.alert) {
            this.alert.showAlert = false;
          }

          this.router.navigate(['/overview']);
        }
      },
      error => (this.alert = new Alert(AlertType.danger, 'The username or password is incorrect'))
    );
  }
}
