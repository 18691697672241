<form [formGroup]="billingForm" (ngSubmit)="save()">
  <div formGroupName="address">
    <div class="form-group row">
      <div class="col-lg-6 col-md-8 col-12">
        <label>Address Line 1:</label>
        <input class="form-control" type="text" name="address1" formControlName="address_1" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6 col-md-8 col-12">
        <label>Address Line 2:</label>
        <input class="form-control" type="text" name="address2" formControlName="address_2" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6 col-md-8 col-12">
        <label>Town/City:</label>
        <input class="form-control" type="text" name="town" formControlName="city" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6 col-md-8 col-12">
        <label>Postal Code:</label>
        <input type="text" class="form-control" name="postalcode" formControlName="postal_code" />
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label>Telephone Number:</label>
      <input class="form-control" type="text" name="telephone" formControlName="tel" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label>VAT Number:</label>
      <input class="form-control" type="text" name="vat" formControlName="vat_no" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-6 col-md-8 col-12">
      <label>Banking Details:</label>
      <ng-template #tipContent>
        To update your banking details please send proof of bank account to <strong>{{ adminEmail }}</strong
        >.
      </ng-template>
      <textarea
        class="form-control"
        type="text"
        name="bankDetails"
        rows="5"
        readonly
        [ngbTooltip]="tipContent"
        triggers="click"
        placement="top"
      >
Name: {{ bankingDetails?.account_name }}&#10;Bank: {{ bankingDetails?.bank }}&#10;Account Number: {{
          bankingDetails?.account_number
        }}&#10;Branch Code: {{ bankingDetails?.branch_code }}
      </textarea>
    </div>
  </div>

  <div class="row my-2">
    <div class="col-lg-6 col-md-8 col-12">
      <div class="row">
        <div class="col-0 col-md"></div>
        <div class="col-6 col-md-4 col-lg-3">
          <button
            class="btn btn-block ac-btn-secondary"
            type="reset"
            [disabled]="billingForm.pristine"
            (click)="cancel()"
          >
            CANCEL
          </button>
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <button
            class="btn btn-block ac-btn-primary"
            [disabled]="billingForm.invalid || billingForm.pristine"
            type="submit"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
