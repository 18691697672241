import { Component, OnInit } from '@angular/core';
import { FooterLinks } from 'core';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { UserService } from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly SHOP_URL = environment.shop_url;
  private readonly BLOG_URL = environment.blog_url;
  private readonly ABOUT_URL = environment.about_us_url;

  footer_links: FooterLinks = {
    register: {
      title: 'Register',
      url: this.SHOP_URL + '/register',
      external: true
    },
    advertise: {
      title: 'Advertise with us',
      url: this.SHOP_URL + '/advertise-with-us',
      external: true
    },
    what_is_agrichecker: {
      title: 'What is Agrichecker?',
      url: this.ABOUT_URL,
      external: true
    },
    blog: {
      title: 'Blog',
      url: this.BLOG_URL,
      external: true
    },
    contact_us: {
      title: 'Contact us',
      url: this.SHOP_URL + '/contact-us',
      external: true
    }
  };

  constructor(private auth: AuthService, private user: UserService) {}

  ngOnInit() {
    this.auth.authStatus.subscribe(authorized => {
      if (authorized) {
        this.user.fetchProfile();
      }
    });
  }
}
