<h4>Recent Orders</h4>
<table class="table">
  <thead>
    <th>Order ID</th>
    <th>Items</th>
    <th class="text-right">Totals</th>
  </thead>
  <tbody>
    <tr *ngFor="let order of recentOrders | async; let i = index">
      <th>
        <a [routerLink]="['/orders', order.id]">{{ order.id }}</a>
      </th>
      <td>
        <div *ngIf="order.order?.items.length > 1; else single">
          <span>{{ order.order?.items.length }} line items...</span>
        </div>
        <ng-template #single>
          <span>{{ (order.order?.items)[0]?.name }}</span>
        </ng-template>
      </td>
      <td class="text-right">{{ order.order?.total | currency: 'ZAR':'symbol-narrow':'1.2-2' }}</td>
    </tr>
  </tbody>
</table>
